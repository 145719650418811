import { Injectable, Injector, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable, of, Subscription } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ProfileInfo } from "../models/profile-info.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  errorMessage = false;

  failedRequests: HttpRequest<any>[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  logout() {
    if (this.getAccessToken()) {
      this.subscriptions.push(this.httpClient.get(`${environment.BACKEND_URL}/api/logout`, {
        headers: {
          Authorization: `Bearer ${this.getAccessToken()}`,
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }).subscribe());
    }
    this.removeLocalStorageData();
    return this.router.navigate(['/login']);
  }

  removeLocalStorageData() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('apiAuthKey');
    localStorage.removeItem('expirationTime');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  login(username: string, password: string) {
    this.subscriptions.push(this.httpClient
      .post(`${environment.BACKEND_URL}/api/auth/token`, this.getRequestBody(username, password),
        {
          headers: {'Content-type': 'application/x-www-form-urlencoded'}
        })
      .subscribe((response: {access_token: string, refresh_token: string, expires_in: any}) => {
        this.setStorageItems(response.access_token, response.refresh_token, response.expires_in);
        if (this.failedRequests.length > 0) {
          this.failedRequests.filter(request => !request.url.includes('renew')
            || !request.url.includes('logout')).forEach(filteredRequest => {
            return this.retryRequest(filteredRequest.clone({setHeaders: {Authorization: `Bearer ${response.access_token}`}})).subscribe();
          });
        }
        // We do this to force the refresh so the replay subjects get reset as well
        window.location.href = 'orderList';
      }, () => this.errorMessage = true));
  }

  getProfileInformation(): Observable<ProfileInfo> {
    return this.httpClient.get<ProfileInfo>(`${environment.BACKEND_URL}/api/me`);
  }

  renewTokenAnRetryRequest(request): Observable<any> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return of(this.logout());
    }
    const headers = {
      Authorization: 'noAuth',
      'Content-type': 'application/x-www-form-urlencoded'
    };
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this.getRefreshToken())
      .set('name', 'webApp');
    return this.httpClient.post<any>(`${environment.BACKEND_URL}/api/auth/renew`, body, {headers})
      .pipe(
        switchMap(
          data => {
            this.setStorageItems(data.access_token, data.refresh_token, data.expires_in);
            request = request.clone({params: request.params.set('is-second-try', true)});
            return this.retryRequest(request);
          }
        ),
        catchError(() => {
          return this.logout();
        })
      );
  }

  setStorageItems(accessToken, refreshToken, expirationTime) {
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('accessToken', accessToken);
  }

  getRequestBody(username, password) {
    return new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', environment.CLIENT_ID)
      .set('name', 'webApp');
  }

  retryRequest(request: HttpRequest<any>) {
    return this.httpClient.request(request);
  }

}
