import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from './app.service';
import { ToastComponent } from './toast/toast.component';
import { DataService } from './service/data.service';
import { DxPopupComponent } from 'devextreme-angular';
import { AuthService } from "./providers/auth.service";
import { SyncService } from "./service/offline/sync.service";
import { environment } from "../environments/environment";
import { InternetService } from "./service/offline/internet.service";
import { ToastType } from "./models/toast.model";
import { ErrorSubmissionService } from "./service/offline/error-submission.service";

@Component({
  selector: 'ibo-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('toast', { static: false }) dxToast: ToastComponent;

  @ViewChild('quitPopup') quitPopup: DxPopupComponent;

  @ViewChild('infoPopup') infoPopup: DxPopupComponent;

  @ViewChild('debugPopup') debugPopup: DxPopupComponent;

  title = 'ibo-app';

  public readonly iboLogo = ``;

  public layoutTitle: string;

  public selectedRoute = "";

  private subscriptions: Subscription[] = [];

  isOnline = true;

  errorMessage = '';

  version: string = environment.version;

  constructor(
    public appService: AppService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
    // Needed so that angular constructs the service
    private syncService: SyncService,
    private internetService: InternetService,
    private errorSubmissionService: ErrorSubmissionService
  ) {
    this.subscriptions.push(this.internetService.onlineState.subscribe(state => {
      this.isOnline = state;
      this.cdr.detectChanges();
    }));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.dataService.layoutTitle$.subscribe(title => {
      this.layoutTitle = title;
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    }));

    this.subscriptions.push(this.appService.notification$.subscribe((notification) => {
      this.dxToast.showNotification(notification);
    }));

  }

  public isSyncing(): boolean {
    return SyncService.isSyncing;
  }

  public isAuthenticated(): boolean {
    if (this.authService.getAccessToken() && this.selectedRoute !== '/login') {
      return true;
    }
  }

  public goToHome = (): void => {
    this.appService.showBackButton = false;
    this.appService.showMessageIcon = false;
    this.router.navigateByUrl('orderList').then();
  }

  public openSettings = (): void => {
    this.appService.callSettingsButton();
  }

  public reloadOrders = (): void => {
    this.appService.callReloadButton();
  }

  public openMessages = (): void => {
    this.router.navigateByUrl('noteList').then();
  }

  public isLoadingMap(): boolean {
    if (this.router.url == '/map') {
      return true;
    }
  }

  public returnButton(): void {
    this.appService.callBackButton();
  }

  public logout(): void {
    this.authService.logout().then();
  }

  public openWindow = (): void => {
    this.quitPopup.instance.show();
  }

  public openInfo = (): void => {
    this.infoPopup.instance.show();
  }

  public openDebug = (): void => {
    this.debugPopup.instance.show();
  }

  public submitUserIssue(): void {
    this.subscriptions.push(
      this.errorSubmissionService.submitUserIssue(this.errorMessage).subscribe(() => {
        this.appService.callNotification({message: 'Fehler erfolgreich übermittelt!', type: ToastType.INFO});
        this.debugPopup.instance.hide();
      }, () => {
        this.appService.callNotification({message: 'Fehler erfolgreich übermittelt!', type: ToastType.INFO})
      }, () => this.errorMessage = '')
    );
  }

  public cancel =  (): void => {
    this.quitPopup.instance.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
