import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConvertHelper, TypeKey } from '@wissenswerft/core/data';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataService } from '../service/data.service';
import { SignaturePad } from 'angular2-signaturepad';
import { SignatureModel } from '../models/signature.model';
import { Address, ContactPerson, Customer, Order, Pest } from '@wissenswerft/ibo-catalog-library';
import { DataDefinitionOptions, DataDefinitionViewModel } from '../view-models/customer-space.view-model';
import { findObjectsForOrder } from "../service/state.utils";
import { StateService } from "../service/state.service";
import { AssetDataWrapper } from "../models/asset-data-wrapper.model";
import { ObjectAndDefinitions } from "../models/objects-and-definitions.model";
import { OfflineCapableUpdaterService } from "../service/offline/offline-capable-updater.service";
import { recordCrash } from "../utils/crashalytics.utils";

@Component({
  selector: 'order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss']
})
export class OrderConfirmComponent implements OnInit, OnDestroy {

  private _customerSignaturePad: SignaturePad;

  private _employeeSignaturePad: SignaturePad;

  private readonly title: string = "Auftrag bestätigen";

  private readonly base64Format: string = "data:image/png;base64,";

  public order: Order;

  public convertHelper = ConvertHelper;

  public subscriptions: Subscription[] = [];

  public customer: Customer;

  public contactPerson: ContactPerson;

  public address: Address;

  public isContractSigned = false;

  public isContractConfirmed = false;

  public signature: SignatureModel;

  public confirmDate: string;

  public confirmed = false;

  public showConfirmDate = false;

  public furtherAppointment: string;

  public toogleQuestionVisible = false;

  public priorities: DataDefinitionOptions[] = [
    { name: 'Ja', value: 'yes' },
    { name: 'Nein', value: 'no' },
    { name: 'Ja, ggf', value: 'ask' }
  ];

  public signaturePadOptions = {
    'minWidth': 1,
    'canvasWidth': 200,
    'canvasHeight': 100,
    disabled: true
  };

  constructor(
    private router: Router,
    private appService: AppService,
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private offlineCapableUpdaterService: OfflineCapableUpdaterService
  ) { }

  ngOnInit() {
    this.dataService.callLayoutTitle(this.title);
    this.appService.layoutPath = "orderConfirm";
    this.subscriptions.push(this.stateService.orderDetail$.subscribe(order => {
      this.order = order;
      this.subscriptions.push(findObjectsForOrder<Customer>(this.stateService, this.order, 'customer').subscribe(customer => {
        this.customer = customer;
        this.address = this.customer.fetchedAddress;
      }));
      this.subscriptions.push(findObjectsForOrder<ContactPerson>(this.stateService, this.order, 'contactPerson').subscribe(contactPerson => {
        this.contactPerson = contactPerson;
      }));

      if (this.order.customerSignatureDate) {
        this.isContractSigned = true;
      }

      if (this.order.confirmOrderDate) {
        this.isContractConfirmed = this.isContractSigned;
        this.confirmDate = this.dataService.prepareSignatureDate(true);
        this.showConfirmDate = true;
        this.confirmed = this.isContractSigned;
      }

      this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
        this.stateService.sendOrderDetail(this.order);
        this.router.navigate(['orderDetail', this.order.id]).then();
      }));

      if (this.order.alreadyAccepted === true) {
        this.confirmed = true;
      }
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(findObjectsForOrder<ObjectAndDefinitions<Pest[]>>(this.stateService, this.order, 'pestsAndDefinition').subscribe(pestsAndDef => {
      const definitions = pestsAndDef.dataDefinition;
      this.dataService.definitionsVM[TypeKey.pest] = definitions;
      const pestsgDefinitionVM = new DataDefinitionViewModel(definitions, {});
      this.dataService.definitionsVM[TypeKey.pest].definitionVM = pestsgDefinitionVM;
    }))

    this.subscriptions.push(this.stateService.getPestCatalogs().subscribe(pestCatalogAndDefinition => {
      const definitions = pestCatalogAndDefinition.dataDefinition;
      this.dataService.definitionsVM[TypeKey.pestCatalog] = definitions;
      const pestsgDefinitionVM = new DataDefinitionViewModel(definitions, {});
      this.dataService.definitionsVM[TypeKey.pestCatalog].definitionVM = pestsgDefinitionVM;
      this.dataService.pestCatalogs = pestCatalogAndDefinition.object;
    }))

  }

  public onSignatureClick(signatureType: 'employee' | 'customer') {
    if (signatureType === "customer" && this.isContractSigned) {
      return;
    }
    if (signatureType === "employee" && (!this.isContractSigned || this.isContractConfirmed)) {
      return;
    }
    if (this.order?.alreadyAccepted === false) {
      this.appService.showBackButton = true;
      this.appService.showMessageIcon = true;
      this.router.navigate(['signature', 'orderConfirm', signatureType]).then();
    }
  }

  // We have to set the signature pad view child like this as it's not yet rendered in the ngOnInit because it depends on the order
  @ViewChild('customerSignaturePad') set customerSignaturePad(signaturePad: SignaturePad) {
    this._customerSignaturePad = signaturePad;
    this.loadCustomerSignature();
  }

  private loadCustomerSignature() {
    if (!this._customerSignaturePad) {
      return;
    }
    if (this.order && (this.order?.alreadyAccepted === undefined || this.order?.alreadyAccepted === false)) {
      this.subscriptions.push(findObjectsForOrder<AssetDataWrapper>(this.stateService, this.order, 'customerSignature').subscribe(signatureWrapper => {
        this.isContractSigned = true;
        const imageBase = this.base64Format + btoa(
          new Uint8Array(signatureWrapper.arrayBuffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this._customerSignaturePad.clear();
        this._customerSignaturePad.fromDataURL(imageBase);
        this.cdr.detectChanges();
      }));
    }
  }

  @ViewChild('employeeSignaturePad') set employeeSignaturePad(signaturePad: SignaturePad) {
    this._employeeSignaturePad = signaturePad;
    this.loadEmployeeSignature();
  }

  private loadEmployeeSignature() {
    if (!this._employeeSignaturePad) {
      return;
    }
    if (this.order && (this.order?.alreadyAccepted === undefined || this.order?.alreadyAccepted === false)) {
      this.subscriptions.push(findObjectsForOrder<AssetDataWrapper>(this.stateService, this.order, 'employeeSignature').subscribe(signatureWrapper => {
        this.isContractConfirmed = true;
        this.confirmed = true;
        const imageBase = this.base64Format + btoa(
          new Uint8Array(signatureWrapper.arrayBuffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this._employeeSignaturePad.clear();
        this._employeeSignaturePad.fromDataURL(imageBase);
        this.cdr.detectChanges();
      }));
      this.confirmDate = this.dataService.prepareSignatureDate(true);
      this.showConfirmDate = true;
    }
  }

  public signContract(): void {
    this.appService.showBackButton = true;
    this.appService.showMessageIcon = true;
    this.router.navigate(['signature', 'orderConfirm', 'customer']).then();
  }

  public confirm(): void {
    this.appService.showBackButton = true;
    this.appService.showMessageIcon = true;
    this.router.navigate(['signature', 'orderConfirm', 'employee']).then();
  }

  public foollowUpNeeded = (isFollowUpNeeded: string) => {
    switch (isFollowUpNeeded) {
      case 'yes':
        return 'Ja'
      case 'no':
        return 'Nein'
      default:
        return '_'
    }
  }

  private updateOrder = (loadNext: boolean) => {
    this.subscriptions.push(this.offlineCapableUpdaterService.updateOrder(
      this.order,
      null,
      () => {
        if (loadNext === true) this.router.navigate(['signatureState', this.order.id]).then()
      }
    ).subscribe(() => {}, error => recordCrash("Error occurred while updating order from order confirm!", error)));
  }

  public signOrder = (): void => {
    this.updateOrder(true);
    this.appService.showBackButton = true;
  }

  public toogleQuestion = (): void => {
    this.toogleQuestionVisible = !this.toogleQuestionVisible;
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}
