import { Injectable } from '@angular/core';
import { PersistenceService, TypeKey } from "@wissenswerft/core/data";
import { DataService } from "../data.service";
import { Observable } from "rxjs";
import { Monitoring, MonitoringDefinition } from "@wissenswerft/ibo-catalog-library";

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor(
    private dataService: DataService,
    private persistenceService: PersistenceService
  ) { }

  public updateMonitoringData(monitoringData: MonitoringDefinition): Observable<any> {
    const objectToSend = {...monitoringData};
    delete objectToSend.offlineId;
    return this.persistenceService.addObjectForUpdate(monitoringData.id, this.dataService.prepareMonitoringDefinitionQuery(monitoringData));
  }

  public updateMonitoring(monitoring: Monitoring): Observable<any> {
    const monitoringToSend = {...monitoring};
    delete monitoringToSend.meta;
    delete monitoringToSend.fetchedObjects;
    delete monitoringToSend.offlineState;
    const multilingualProperties = this.dataService.definitionsVM[TypeKey.monitoring].definitionVM.multilingualProperties;
    const listProperties = this.dataService.definitionsVM[TypeKey.monitoring].definitionVM.listProperties;
    const object = this.dataService.createPersistObject(monitoringToSend, multilingualProperties, listProperties);
    return monitoringToSend.id ? this.persistenceService.addObjectForUpdate(monitoringToSend.id, object) : this.persistenceService.addObjectForInsert(TypeKey.monitoring, object);
  }

  public addMonitoringData(monitoringData: MonitoringDefinition): Observable<MonitoringDefinition> {
    const query = this.dataService.prepareMonitoringDefinitionQuery(monitoringData);
    return this.persistenceService.addObjectForInsert(TypeKey.monitoringDefinition, query);
  }

}
