import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { NavigationTours } from "@wissenswerft/ibo-catalog-library";
import { CoreDataService } from "@wissenswerft/core/data";
import { map, switchMap } from "rxjs/operators";
import { ProfileInfo } from "../models/profile-info.model";

@Injectable({
  providedIn: 'root'
})
export class TourService {
  //TODO: Test tour after switch

  constructor(
    private coreDataService: CoreDataService
  ) { }

  public getRelevantTourWithFallback(profileInfo: ProfileInfo): Observable<NavigationTours | undefined> {
    const now = new Date().toISOString().split(".")[0];
    return this.coreDataService.getItemsBySpoqlQuery<NavigationTours[]>(
      'tour',
      `{ property 'startDate' before '${now}'} and { property 'endDate' after '${now}' } and { property 'user' eq ${profileInfo.id} } limit 1`
    ).pipe( // Falls back to earliest not started tour if none is active
      switchMap(tours => {
        if (tours.length === 0) {
          return this.useNextTourToStart(now, profileInfo);
        }
        return of(tours[0]);
      })
    );
  }

  private useNextTourToStart(now: string, profileInfo: ProfileInfo): Observable<NavigationTours | undefined> {
    return this.coreDataService.getItemsBySpoqlQuery<NavigationTours[]>(
      'tour',
      `{ property 'startDate' after '${now}' } and { property 'user' eq ${profileInfo.id} } orderby {property 'startDate' ascending} limit 1`
    ).pipe(
      switchMap(fallbackTours => { // If no unstarted tour, use the last started one
        if (fallbackTours.length === 0) {
          return this.useLastStartedTour(now, profileInfo);
        }
        return of(fallbackTours[0]);
      })
    )
  }

  private useLastStartedTour(now: string, profileInfo: ProfileInfo): Observable<NavigationTours | undefined> {
    return this.coreDataService.getItemsBySpoqlQuery<NavigationTours[]>(
      'tour',
      `{ property 'startDate' before '${now}' } and { property 'user' eq ${profileInfo.id} } orderby {property 'startDate' descending} limit 1`
    ).pipe(
      map(tours => {
        if (tours.length > 0) {
          return tours[0];
        }
        return undefined;
      })
    )
  }

}
