import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignatureComponent } from './signature/signature.component';
import { OrderConfirmComponent } from './order-confirm/order-confirm.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderlistComponent } from './order-list/order-list.component';
import { SignatureStateComponent } from './signature-state/signature-state.component';
import { ControlProtocolComponent } from './control-protocol/control-protocol.component';
import { ServiceAndProductComponent } from './control-protocol/add-service-product/service-product.component';
import { NoteComponent } from './note/note.component';
import { NoteListComponent } from './note-list/note-list.component';
import { AuthGuard } from "./providers/auth.guard";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  {
    path: 'signature/:name/:user',
    component: SignatureComponent, canActivate: [AuthGuard]
  },
  {
    path: 'signatureState/:id',
    component: SignatureStateComponent, canActivate: [AuthGuard]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'orderList',
    component: OrderlistComponent, canActivate: [AuthGuard]
  },
  {
    path: 'orderDetail/:id',
    component: OrderDetailComponent, canActivate: [AuthGuard]
  },
  {
    path: 'orderConfirm/:id',
    component: OrderConfirmComponent, canActivate: [AuthGuard]
  },
  {
    path: 'controlProtocol/:id',
    component: ControlProtocolComponent, canActivate: [AuthGuard]
  },
  {
    path: 'serviceAndProduct',
    component: ServiceAndProductComponent, canActivate: [AuthGuard]
  },
  {
    path: 'note',
    component: NoteComponent, canActivate: [AuthGuard]
  },
  {
    path: 'noteList',
    component: NoteListComponent, canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'orderList'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {

}
