import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Order, Service, ServiceCatalog } from '@wissenswerft/ibo-catalog-library';
import { DxPopupComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { DataService } from '../../service/data.service';
import { StateService } from "../../service/state.service";
import { findObjectsForOrder } from "../../service/state.utils";
import { OfflineCapableUpdaterService } from "../../service/offline/offline-capable-updater.service";
import { recordCrash } from "../../utils/crashalytics.utils";

@Component({
  selector: 'service-product',
  templateUrl: './service-product.component.html',
  styleUrls: ['./service-product.component.scss']
})
export class ServiceAndProductComponent implements OnInit, OnDestroy {

  @ViewChild('assignServiceWindow') assignServiceWindow: DxPopupComponent;

  private readonly title: string = "Leistungen / Produkte auswählen";

  public subscriptions: Subscription[] = [];

  public serviceCatalog: ServiceCatalog[] = [];

  private order: Order;

  constructor(
    private router: Router,
    private appService: AppService,
    public dataService: DataService,
    private stateService: StateService,
    private offlineCapableUpdaterService: OfflineCapableUpdaterService
  ) { }

  ngOnInit() {
    this.dataService.callLayoutTitle(this.title);

    this.subscriptions.push(this.stateService.orderDetail$.subscribe(order => {
        this.order = order;
        this.subscriptions.push(this.stateService.serviceCatalogsAndDef.subscribe(serviceCatalogsAndDef => {
          this.subscriptions.push(findObjectsForOrder<Service[]>(this.stateService, this.order, 'services').subscribe(services => {
            this.serviceCatalog = serviceCatalogsAndDef.object.filter(serviceCatalog => !services.some(service => service.designation === serviceCatalog.designation));
          }, () => this.serviceCatalog = serviceCatalogsAndDef.object));
        }));
      }
    ));

    this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
      this.router.navigate(['controlProtocol', this.order.id]).then();
    }))
  }

  public openTagDialog(): void {
    this.assignServiceWindow.instance.show();
  }

  public closePopup(): void {
    this.assignServiceWindow.instance.hide();
  }

  public markServiceAsPerformed(e): void {
    const selectedServiceCatalog = e.itemData;
    const service = {
      description: selectedServiceCatalog.description,
      designation: selectedServiceCatalog.designation,
      unit: selectedServiceCatalog.unit,
      usedQuantity: 0,
      plannedQuantity: 0
    };
    this.offlineCapableUpdaterService.createService(service, this.order)
      .subscribe(() => {}, error => recordCrash("Error occurred while marking service as performed!!", error));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
