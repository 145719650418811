import { Injectable, OnDestroy } from '@angular/core';
import { OfflineCapableUpdaterService } from "./offline-capable-updater.service";
import { InternetService } from "./internet.service";
import { Order } from "@wissenswerft/ibo-catalog-library";
import { combineLatest, concat, forkJoin, from, Observable, Subscription } from "rxjs";
import { StateService } from "../state.service";
import { map, switchMap, tap } from "rxjs/operators";
import { AppService } from "../../app.service";
import { ToastType } from "../../models/toast.model";
import { AuthService } from "../../providers/auth.service";
import { StorageService } from "./storage.service";
import { recordCrash } from "../../utils/crashalytics.utils";
import { DataService } from "../data.service";
import { ErrorSubmissionService } from "./error-submission.service";

@Injectable({
  providedIn: 'root'
})
// Workflow here is:
// update pest catalogs -> update offline orders (synchronously calling everything in `updateOfflineOrder` via callbacks) -> update offline notes (synchronously again).
// All synchronous calls are done via subscribe on either one observable or a concatenation result of multiple observables to ensure data safety and thread integrity.
// Whoever you are, I'm sorry for this, but it was necessary :(
export class SyncService implements OnDestroy {

  public static isSyncing = false;

  private updatesHappened = false;

  private internetConnection = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private offlineCapableUpdaterService: OfflineCapableUpdaterService,
    private internetService: InternetService,
    private stateService: StateService,
    private appService: AppService,
    private authService: AuthService,
    private storageService: StorageService,
    private dataService: DataService,
    private errorSubmissionService: ErrorSubmissionService
  ) {
    this.subscriptions.push(combineLatest([
      this.internetService.onlineState,
      this.stateService.dataFinishedLoading()//We have to ensure that the definitions were loaded for the updates because of the update workflow...
    ]).subscribe(onlineStateAndRubbish => {
      const internetStatusChanged = this.internetConnection !== onlineStateAndRubbish[0];
      this.internetConnection = onlineStateAndRubbish[0];
      if (internetStatusChanged && this.internetConnection && !SyncService.isSyncing && this.authService.getAccessToken()) {
        this.beginSyncing();
      }
    }, error => this.recordSyncIssue("Error while setting up the internet connection and loaded data subscription!", error)));
  }

  private recordSyncIssue(message, error) {
    recordCrash(message, error);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private beginSyncing() {
    SyncService.isSyncing = true;
    this.updatesHappened = false;
    this.updateOfflinePestCatalogs(() => // This is where the hell begins. We call everything with a callback because we need everything to be a synchronous operation.
      this.updateOfflineOrders(() =>
        this.updateOfflineNotes(() => {
          SyncService.isSyncing = false;
          if (this.updatesHappened) {
            this.appService.callNotification({ message: 'Wieder online. Datenabgleich abgeschlossen, Daten aktualisieren!', type: ToastType.INFO })
            this.stateService.initializeData();
          }
          this.updatesHappened = false;
        })
      )
    );
  }

  private updateOfflineNotes(oncomplete: () => void) {
    this.subscriptions.push(
      this.getOfflineDeletionRequests().subscribe(() => {},
        error => this.recordSyncIssue("Error occurred when syncing deleted notes!", error),
        () =>
          this.subscriptions.push(this.getOfflineCreationRequests().subscribe(() => {},
            error => this.recordSyncIssue("Error occurred when syncing created notes!", error),
            () => oncomplete()
          ))
      )
    );
  }

  private getOfflineCreationRequests(): Observable<any> {
    return from(this.storageService.getNotesToCreate())
      .pipe(
        switchMap(notesToCreate => forkJoin(notesToCreate.map(noteToCreate => this.offlineCapableUpdaterService.createNote(noteToCreate.note, noteToCreate.orderId, noteToCreate.note.offlineState?.attachment, true))))
      );
  }

  private getOfflineDeletionRequests() {
    return from(this.storageService.getNotesToDelete())
      .pipe(
        switchMap(deletesNotes => forkJoin(deletesNotes.map(deletedNoteData => this.offlineCapableUpdaterService.deleteNote(deletedNoteData.note, deletedNoteData.orderId, true))))
      );
  }

  private updateOfflineOrders(oncomplete: () => void) {
    this.storageService.getOrders().then(storedOrders => {
      if (storedOrders.length >= 1) {
        this.removeStaleOrders(storedOrders).subscribe(storedOrdersNoStale => {
          if (storedOrdersNoStale.length >= 1) {
            this.updatesHappened = true;
            this.updateOrder(storedOrdersNoStale[0], storedOrdersNoStale, oncomplete);
          } else {
            oncomplete();
          }
        })
      } else {
        oncomplete();
      }
    });
  }

  // We sometimes end up with stale orders in the database. <Hopefully this will be fixed>, but this still checks
  // to not update any orders that are not completed in the db, but completed in the backend
  private removeStaleOrders(orders: Order[]): Observable<Order[]> {
    return this.dataService.getAllItemsByIds<Order>('order', orders.map(order => order.id))
      .pipe(
        map(latestOnlineOrders => {
          return orders.filter(storedOrder => {
            const latestOnlineOrderState = latestOnlineOrders.find(latestOnlineOrder => latestOnlineOrder.id === storedOrder.id);
            if (!latestOnlineOrderState) {
              this.errorSubmissionService.submitAutomaticIssue("Found order not accessible from the backend!", storedOrder);
              return false;
            }
            if (latestOnlineOrderState.status && latestOnlineOrderState.status !== 'NEU' && storedOrder.status === 'NEU') {
              this.errorSubmissionService.submitAutomaticIssue("Found stale order!", storedOrder);
              return false;
            }
            return true;
          })
        }),
        tap(nonStaleOrders => this.storageService.saveOrders(nonStaleOrders))
      );
  }

  private updateOrder(order: Order, remainingOrders: Order[], oncomplete: () => void) {// We have to recursively update each order because we can only do it synchronously and this was the best way I hope. I'm sorry.
    if (remainingOrders.length === 1) { // No more orders, so we can stop this hell
      this.updateOfflineOrder(order, () => oncomplete());
    } else { // Remove order from array and continue with next one
      remainingOrders.splice(0, 1);
      this.updateOfflineOrder(order, () => this.updateOrder(remainingOrders[0], remainingOrders, oncomplete));
    }
  }

  private updateOfflineOrder(order: Order, next: () => void) {
    if (!this.internetConnection) {
      next();
    } else {
      if (order.offlineState) {//Requests are done synchronously, I know it's a callback hell, but it seems like the most efficient & clean way to do this...
        this.syncSignatures(
          order,
          () => this.syncServices(
            order,
            () => this.syncAttachments(
              order,
              () => this.syncMonitoring(
                order,
                () => this.syncPests(
                  order,
                  () => this.finishOrderSync(order, () => next())
                )
              )
            )
          )
        );
      }
    }
  }

  private finishOrderSync(order: Order, oncomplete: () => void) { // Final update and removal
    this.subscriptions.push(this.offlineCapableUpdaterService.updateOrder(order, undefined, undefined, true)
      .subscribe(() => {}, error => this.recordSyncIssue("Error occurred when syncing orders!", error), oncomplete));
  }

  private updateOfflinePestCatalogs(oncomplete: () => void) {
    this.storageService.getPestCatalogs().then(catalogs => {
      if (catalogs && catalogs.length > 0) {
        this.updatesHappened = true;
      }
      this.subscriptions.push(concat(
        ...catalogs.map(pestCatalogIdent => this.offlineCapableUpdaterService.createPestCatalog(pestCatalogIdent, null, true))
      ).subscribe(() => {}, error => this.recordSyncIssue("Error occurred when syncing pest catalogs!", error), () => oncomplete()));
    })
  }

  private syncAttachments(order: Order, next: () => void) {
    if (order.offlineState.attachments) {
      const attachmentsToSend = [...order.offlineState.attachments]; // We copy the attachments, so we can modify the original array
      this.subscriptions.push(concat(...attachmentsToSend.map(attachmentToSend =>
        this.offlineCapableUpdaterService.uploadAttachment(order, undefined, attachmentToSend, true)
      )).subscribe(() => {}, error => this.recordSyncIssue("Error occurred when syncing attachments!", error), () => next()));
    } else {
      next();
    }
  }

  private syncServices(order: Order, next: () => void) {
    const servicesToSend = order.offlineState?.servicesToModify ? Object.assign([], order.offlineState.servicesToModify) : []; // We copy the services, so we can modify the original array
    this.subscriptions.push(concat(...servicesToSend.map(serviceToModify =>
      this.offlineCapableUpdaterService.updateOrCreateService(serviceToModify, order, true)
    )).subscribe(() => {}, error => this.recordSyncIssue("Error occurred when syncing services!", error), () => next()));
  }

  private syncMonitoring(order: Order, next: () => void) {
    const monitoring = order.offlineState?.monitoring;
    if (monitoring) {
      const monitoringDataToSend = [...(monitoring?.offlineState?.monitoringData ?? [])]; // // We copy the monitoring data, so we can modify the original array
        this.subscriptions.push(concat(...monitoringDataToSend.map(monitoringData => {
          if (monitoringData.id) {
            return this.offlineCapableUpdaterService.updateMonitoringData(monitoringData, monitoring, order, true);
          }
          return this.offlineCapableUpdaterService.addMonitoringData(monitoringData, monitoring, order, undefined, true);
        })).subscribe(() => {}, error => this.recordSyncIssue("Error occurred when syncing monitorings!", error), () => {
          this.subscriptions.push(this.offlineCapableUpdaterService.updateMonitoring(monitoring, order, false, true)
            .subscribe(() => next()));
        }));
    } else {
      next();
    }
  }

  private syncPests(order: Order, next: () => void) {
    const offlinePests = Object.assign([], order.offlineState?.pests ?? []) // We copy the pests, so we can modify the original array
    this.subscriptions.push(
      concat(...offlinePests.map(offlinePest => this.offlineCapableUpdaterService.addPest(order, offlinePest, true)))
      .subscribe(() => {}, error => this.recordSyncIssue("Error occurred when syncing pests!", error), () => next())
    );
  }

  private syncSignatures(order: Order, next: () => void) {
    const signatureUploads: Observable<any>[] = [];
    if (order.offlineState.employeeSignature) {
      signatureUploads.push(this.offlineCapableUpdaterService.uploadSignature(
        order,
        'employee',
        order.offlineState.employeeSignature,
        true
      ));
    }
    if (order.offlineState.customerSignature) {
      signatureUploads.push(this.offlineCapableUpdaterService.uploadSignature(
        order,
        'customer',
        order.offlineState.customerSignature,
        true
      ));
    }
    if (order.offlineState.confirmSignature) {
      signatureUploads.push(this.offlineCapableUpdaterService.uploadSignature(
        order,
        'confirm',
        order.offlineState.confirmSignature,
        true
      ));
    }
    if (order.offlineState.employeeConfirmSignature) {
      signatureUploads.push(this.offlineCapableUpdaterService.uploadSignature(
        order,
        'employeeConfirm',
        order.offlineState.employeeConfirmSignature,
        true
      ));
    }
    this.subscriptions.push(concat(...signatureUploads).subscribe(() => {}, error => this.recordSyncIssue("Error occurred when syncing signatures!", error), () => next()));
  }

}
