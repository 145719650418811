import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConvertHelper } from '@wissenswerft/core/data';
import { Address, ContactPerson, Customer, Order } from '@wissenswerft/ibo-catalog-library';
import { StateService } from "../../service/state.service";
import { Router } from "@angular/router";
import { DataService } from "../../service/data.service";
import { AppService } from "../../app.service";
import { findObjectsForOrder } from "../../service/state.utils";
import { Subscription } from "rxjs";
import { recordCrash } from "../../utils/crashalytics.utils";


@Component({
    selector: 'order-list-item',
    templateUrl: './order-list-item.component.html',
    styleUrls: ['./order-list-item.component.scss']
})
export class OrderlistItemComponent implements OnInit, OnDestroy {

    @Input() order: Order;

    @Output() messageClick: EventEmitter<boolean> = new EventEmitter(false);

    public contactPerson: ContactPerson;

    public address: Address;

    public convertHelper = ConvertHelper;

    public customer: Customer;

    private subscriptions: Subscription[] = [];

    constructor(
      private stateService: StateService,
      private router: Router,
      private dataService: DataService,
      private appService: AppService,
    ) {
      this.initData();
    }

    private initData() {
      if (this.order) {
        this.subscriptions.push(findObjectsForOrder<Customer>(this.stateService, this.order, 'customer').subscribe(customer => {
          if (customer) {
            this.customer = customer;
            this.address = customer.fetchedAddress;
          }
        }));
        this.subscriptions.push(findObjectsForOrder<ContactPerson>(this.stateService, this.order, 'contactPerson').subscribe(contactPerson => {
          this.contactPerson = contactPerson;
        }));
      }
    }

    ngOnInit() {
        this.initData();
    }

    public openNotes(): void{
       this.messageClick.emit(true);
    }

    public onOrderClick() {
      this.dataService.isWorkTimeCalculated = false;
      this.stateService.sendOrderDetail(this.order);
      this.appService.showBackButton = true;
      this.appService.showMessageIcon = true;
      this.router.navigate(['orderDetail', this.order.id]).then();
    }

  ngOnDestroy(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}



