export const MONITORING_CODES = [
  { name: 'RB (Rattenbox)', value: 'RB' },
  { name: 'MB (Mäusebox)', value: 'MB' },
  { name: 'SF (Schabenindikatorfalle)', value: 'SF' },
  { name: 'MF (Mottenfalle)', value: 'MF' },
  { name: 'SIF (Silberfischchenindikatorfalle)', value: 'SIF' },
  { name: 'PIF (Papierfischchenindikatorfalle)', value: 'PIF' },
  { name: 'BIF (Bettwanzenindikatorfalle)', value: 'BIF' },
  { name: 'PAK (Pharaoameisenköder)', value: 'PAK' }
]
