import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Observable, ReplaySubject, Subscription } from "rxjs";
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class InternetService implements OnDestroy {

  public isOnline = false;

  private onlineStateSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public readonly onlineState: Observable<boolean> = this.onlineStateSubject.asObservable();

  constructor() {
    Network.getStatus().then(status => {
      this.isOnline = status.connected;
      this.onlineStateSubject.next(this.isOnline);
    });
    Network.addListener('networkStatusChange', status => {
      this.isOnline = status.connected;
      this.onlineStateSubject.next(this.isOnline);
    });
  }

  ngOnDestroy(): void {
    Network.removeAllListeners().then();
  }

}
