import { Injectable } from '@angular/core';
import {
  Monitoring,
  MonitoringDefinition, OfflineAttachment,
  Order,
  Pest, PestCatalog,
  Service,
  SignatureType
} from "@wissenswerft/ibo-catalog-library";
import { StateManagerService } from "../state-manager.service";
import { CoreDataService, PersistenceService, TypeKey } from "@wissenswerft/core/data";
import { OrderViewModel } from "../../view-models/order.view-model";
import { DataService } from "../data.service";
import { EMPTY, Observable, of, throwError, TimeoutError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { InternetService } from "./internet.service";
import { OfflineService } from "./offline.service";
import { ToastType } from "../../models/toast.model";
import { AppService } from "../../app.service";
import { Note } from "../../models/note.model";
import { ServiceProductService } from "./service-product.service";
import { MonitoringService } from "./monitoring.service";
import { catchError, retry, switchMap, tap, timeout } from "rxjs/operators";
import { SyncService } from "./sync.service";
import { base64StringToBlob } from "blob-util";
import { ErrorSubmissionService } from "./error-submission.service";

@Injectable({
  providedIn: 'root'
})
// This is used to handle offline update situations. Instead of sending the request it will save the state on the order
// and, when back online it will update it at once. This could use a refactoring as there's a lot of leftover logic
// from the previous implementations, but there's no more time for that :(
export class OfflineCapableUpdaterService {

  private readonly REQUEST_TIMEOUT = 5000;

  constructor(
    private stateManagerService: StateManagerService,
    private coreDataService: CoreDataService,
    private dataService: DataService,
    private serviceProductService: ServiceProductService,
    private persistenceService: PersistenceService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private internetService: InternetService,
    private offlineService: OfflineService,
    private monitoringService: MonitoringService,
    private appService: AppService,
    private errorSubmissionService: ErrorSubmissionService
  ) { }

  uploadSignature(order: Order, signatureType: SignatureType, image?: string, isSync?: boolean): Observable<any> {
    const signature = base64StringToBlob(image, 'image/jpg');
    if (this.shouldPerformOnlineOperation(isSync)) {
      return this.coreDataService.insertAsset(signature, 'image.png')
        .pipe(
          timeout(this.REQUEST_TIMEOUT),
          catchError(error => {
            if (this.isErrorDueToConnectionIssues(error)) {
              this.onOfflineSignatureUpdate(isSync, image, order, signatureType)
              return EMPTY;
            }
            this.onErrorNotNetworkRelated('Encountered issue when uploading signature:\n', error, { image });
            return throwError(error);
          }),
          switchMap(asset => {
            let returnPath: string = undefined;
            switch (signatureType) {
              case "customer":
                order.offlineState.customerSignature = undefined;
                order.customerSignature = asset.body.id;
                if (!isSync) {
                  order.customerSignatureDate = new Date();
                }
                returnPath = 'orderConfirm';
                break;
              case "employee":
                order.offlineState.employeeSignature = undefined;
                order.employeeSignature = asset.body.id;
                if (!isSync) {
                  order.confirmOrderDate = new Date();
                }
                returnPath = 'orderConfirm';
                break;
              case "confirm":
                order.offlineState.confirmSignature = undefined;
                order.confirmSignature = asset.body.id;
                if (!isSync) {
                  order.verifiedOrderDate = new Date();
                }
                returnPath = 'controlProtocol';
                break;
              case "employeeConfirm":
                order.offlineState.employeeConfirmSignature = undefined;
                order.employeeConfirmSignature = asset.body.id;
                if (!isSync) {
                  order.closedContractDate = new Date();
                }
                returnPath = 'controlProtocol';
            }
            return this.updateOrder(order, isSync ? undefined : returnPath, null)
              .pipe(
                timeout(this.REQUEST_TIMEOUT),
                catchError(error => {
                  if (this.isErrorDueToConnectionIssues(error)) {
                    this.offlineService.addOrderToStorage(order);
                    return EMPTY;
                  }
                  return throwError(error);
                }),
                tap(() => signature.arrayBuffer().then(arrayBuffer => this.stateManagerService.updateSignature(order.id, arrayBuffer, signatureType)))
              );
          })
        );
    } else {
      this.onOfflineSignatureUpdate(isSync, image, order, signatureType)
    }
    return of(1);
  }

  private onOfflineSignatureUpdate(isSync: boolean, signature: string, order: Order, signatureType: "customer" | "employee" | "confirm" | "employeeConfirm") {
    if (!isSync) {
      switch (signatureType) {
        case "customer":
          order.customerSignatureDate = new Date();
          break;
        case "employee":
          order.confirmOrderDate = new Date();
          break;
        case "confirm":
          order.verifiedOrderDate = new Date();
          break;
        case "employeeConfirm":
          order.closedContractDate = new Date();
          break;
      }
      this.offlineService.addSignatureOffline(order, signature, signatureType);
      const navigateTo = (signatureType === 'customer' || signatureType === 'employee') ? 'orderConfirm' : 'controlProtocol';
      this.router.navigate([navigateTo, order.id]).then();
    }
  }

  deleteNote(note: Note, orderId: number, isSync?: boolean) {
    if (this.shouldPerformOnlineOperation(isSync)) {
      if (!note.id) {
        this.offlineService.removeNoteFromCreated(note, orderId, () => this.stateManagerService.refreshNotes());
        if (!isSync) this.appService.callNotification({message: 'Anmerkung entfernt', type: ToastType.INFO});
      } else {
        return this.coreDataService.deleteNoteByItemId<any>(note.id).pipe(
          timeout(this.REQUEST_TIMEOUT),
          catchError(error => {
            if (this.isErrorDueToConnectionIssues(error)) {
              this.onOfflineNoteDeletion(isSync, note, orderId);
              return EMPTY;
            }
            if (isSync) {
              this.offlineService.removeNoteFromDeleted(note, orderId, () => this.stateManagerService.updateNote(orderId, note, true));
            }
            this.onErrorNotNetworkRelated('Encountered issue when deleting note: \n', error, note);
            return throwError(error);
          }),
          tap(() => {
            this.offlineService.removeNoteFromDeleted(note, orderId, () => this.stateManagerService.updateNote(orderId, note, true));
            if (!isSync) this.appService.callNotification({message: 'Anmerkung entfernt', type: ToastType.INFO});
          })
        );
      }
    } else {
      this.onOfflineNoteDeletion(isSync, note, orderId);
    }
    return of(1);
  }

  private shouldPerformOnlineOperation(isSync: boolean) {
    return this.internetService.isOnline && (isSync || !SyncService.isSyncing);
  }

  private onOfflineNoteDeletion(isSync: boolean, note: Note, orderId: number) {
    if (!note.id) {
      this.offlineService.removeNoteFromCreated(note, orderId, () => this.stateManagerService.refreshNotes());
      if (!isSync) this.appService.callNotification({message: 'Anmerkung entfernt', type: ToastType.INFO});
    } else if (!isSync) {
      this.offlineService.addNoteForDeletion(note, orderId);
      this.appService.callNotification({message: 'Anmerkung entfernt', type: ToastType.INFO});
    }
  }

  createNote(note: Note, orderId: number, attachment?: string, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      const blobImage = attachment ? base64StringToBlob(attachment, 'image/jpg') : null;
      if (blobImage) {
        return this.coreDataService.insertNote(orderId, note.text, blobImage, 'image.png')
          .pipe(
            timeout(this.REQUEST_TIMEOUT),
              catchError(error => {
              if (this.isErrorDueToConnectionIssues(error)) {
                this.onOfflineNoteCreation(isSync, note, orderId, attachment);
                return EMPTY;
              }
                this.onErrorNotNetworkRelated('Encountered issue when creating note with image: \n', error, { note, attachment });
                return throwError(error);
            }),
            tap(createdNote => {
              createdNote.fetchedObjects = {};
              blobImage.arrayBuffer().then(arrayBuffer => {
                createdNote.offlineId = note.offlineId;
                createdNote.orderId = note.orderId;
                this.offlineService.removeNoteFromCreated(note, orderId, () => this.stateManagerService.updateNote(orderId, createdNote));
                note.offlineState = {};
                createdNote.fetchedObjects.attachments = [{objectId: createdNote.id, arrayBuffer}];
                if (!isSync) this.router.navigateByUrl('noteList').then();
              })
            })
          );
      } else {
        const updateNote = {...note};
        delete updateNote.offlineState;
        delete updateNote.fetchedObjects;
        delete updateNote.orderId;
        delete updateNote.offlineId;
        return this.persistenceService.addNote(updateNote, orderId).pipe(
          timeout(this.REQUEST_TIMEOUT),
          catchError(error => {
            if (this.isErrorDueToConnectionIssues(error)) {
              this.onOfflineNoteCreation(isSync, note, orderId, attachment);
              return EMPTY;
            }
            this.onErrorNotNetworkRelated('Encountered issue when creating note: \n', error, note);
            return throwError(error);
          }),
          tap(createdNote => {
            createdNote.offlineId = note.offlineId;
            createdNote.orderId = note.orderId;
            this.offlineService.removeNoteFromCreated(note, orderId, () => this.stateManagerService.updateNote(orderId, createdNote));
            note.offlineState = {};
            createdNote.fetchedObjects = {};
            if (!isSync) this.router.navigateByUrl('noteList').then();
          })
        );
      }
    } else {
      this.onOfflineNoteCreation(isSync, note, orderId, attachment);
    }
    return of(1);
  }

  private onOfflineNoteCreation(isSync: boolean, note: Note, orderId: number, attachment?: string) {
    if (!isSync) {
      this.offlineService.addNoteForCreation(note, orderId, attachment);
      this.router.navigateByUrl('noteList').then();
    }
  }

  createService(service: Service, order: Order, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      return this.serviceProductService.updatePerformedService(service).pipe(
        timeout(this.REQUEST_TIMEOUT),
        retry(2),
        catchError(error => {
          if (this.isErrorDueToConnectionIssues(error)) {
            this.onOfflineServiceCreation(isSync, service, order);
            return EMPTY;
          }
          this.onErrorNotNetworkRelated('Encountered issue when creating service: \n', error, service);
          return throwError(error);
        }),
        tap(performedService => {
          order.services.push(performedService.id);
          this.offlineService.removeServiceFromStorage(service, order);
          this.stateManagerService.updateObjectState("services", performedService);
          if (!isSync) {
            this.appService.callNotification({message: 'Zugewiesene Dienstleistung', type: ToastType.SUCCESS});
          }
        }, () => {
          if (!isSync) {
            this.appService.callNotification({
              message: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
              type: ToastType.ERROR
            });
          }
        }),
        switchMap(() => {
          return this.updateOrder(order, isSync ? undefined : 'controlProtocol');
        })
      );
    } else {
      this.onOfflineServiceCreation(isSync, service, order);
    }
    return of(1);
  }

  private onOfflineServiceCreation(isSync: boolean, service: Service, order: Order) {
    if (!isSync) {
      this.offlineService.addServiceToStorage(service, order);
      this.appService.callNotification({message: 'Zugewiesene Dienstleistung', type: ToastType.SUCCESS});
      this.router.navigate(['controlProtocol', order.id]).then();
    }
  }

  updateOrCreateService(service: Service, order: Order, isSync?: boolean): Observable<any> {
    if (!service.id) {
      return this.createService(service, order, isSync);
    }
    return this.updateService(service, order, isSync);
  }

  updateService(service: Service, order: Order, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      return this.serviceProductService.updateService(service)
        .pipe(
          timeout(this.REQUEST_TIMEOUT),
          catchError(error => {
            if (this.isErrorDueToConnectionIssues(error)) {
              if (!isSync) {
                this.offlineService.addServiceToStorage(service, order);
              }
              return EMPTY;
            }
            this.onErrorNotNetworkRelated('Encountered issue when updating service: \n', error, service);
            return throwError(error);
          }),
          tap(updatedService => {
            this.stateManagerService.updateObjectState("services", updatedService);
            this.offlineService.removeServiceFromStorage(service, order);
          })
        );
    } else if (!isSync) {
      this.offlineService.addServiceToStorage(service, order);
    }
    return of(1);
  }

  public updateMonitoring(monitoring: Monitoring, order: Order, goToOrderList?: boolean, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      return this.monitoringService.updateMonitoring(monitoring).pipe(
        timeout(this.REQUEST_TIMEOUT),
        retry(2),
        catchError(error => {
          if (this.isErrorDueToConnectionIssues(error)) {
            this.onOfflineMonitoringUpdate(isSync, monitoring, order, goToOrderList);
            return EMPTY;
          }
          this.onErrorNotNetworkRelated('Encountered issue when updating monitoring: \n', error, monitoring);
          return throwError(error);
        }),
        tap(newMonitoring => {
          monitoring.id = newMonitoring.id;
          this.offlineService.removeMonitoringFromStorage(order, () => this.stateManagerService.updateObjectState("monitoring", monitoring));
          if (goToOrderList === true) {
            this.appService.callNotification({ message: 'Auftrag aktualisiert', type: ToastType.SUCCESS });
            this.router.navigate(['signatureState', order.id]).then();
          }
        })
      );
    } else {
      this.onOfflineMonitoringUpdate(isSync, monitoring, order, goToOrderList);
    }
    return of(1);
  }

  private onOfflineMonitoringUpdate(isSync: boolean, monitoring: Monitoring, order: Order, goToOrderList: boolean) {
    if (!isSync) {
      this.offlineService.addMonitoringToStorage(monitoring, order);
      if (goToOrderList) {
        this.router.navigate(['signatureState', order.id]).then();
        this.appService.callNotification({message: 'Auftrag aktualisiert', type: ToastType.SUCCESS});
      }
    }
  }

  public addMonitoringData(monitoringData: MonitoringDefinition, monitoring: Monitoring, order: Order, onComplete: () => void, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      return this.monitoringService.addMonitoringData(monitoringData).pipe(
        timeout(this.REQUEST_TIMEOUT),
        catchError(error => {
          if (this.isErrorDueToConnectionIssues(error)) {
            this.onOfflineAddMonitoringData(isSync, monitoringData, monitoring, order, onComplete);
            return EMPTY;
          }
          this.onErrorNotNetworkRelated('Encountered issue when adding monitoring data: \n', error, monitoringData);
          return throwError(error);
        }),
        switchMap((createdMonitoringData) => {
          createdMonitoringData.offlineId = monitoringData.offlineId;
          if (!monitoring.monitoringData) {
            monitoring.monitoringData = [];
          }
          monitoring.monitoringData.push(createdMonitoringData.id);
          if (!monitoring.fetchedObjects.monitoringData) {
            monitoring.fetchedObjects.monitoringData = [];
          }
          monitoring.fetchedObjects.monitoringData.push(createdMonitoringData);
          return this.updateMonitoring(monitoring, order, false).pipe(
            timeout(this.REQUEST_TIMEOUT),
              catchError(error => {
              if (this.isErrorDueToConnectionIssues(error)) {
                this.onOfflineAddMonitoringData(isSync, monitoringData, monitoring, order, onComplete);
                return EMPTY;
              }
              return throwError(error);
            }),
            tap(() => {
              this.offlineService.removeMonitoringDataFromStorage(monitoringData, monitoring, order);
              if (onComplete) {
                onComplete();
              }
            })
          );
        })
      );
    } else {
      this.onOfflineAddMonitoringData(isSync, monitoringData, monitoring, order, onComplete);
    }
    return of(1);
  }

  private onOfflineAddMonitoringData(isSync: boolean, monitoringData: MonitoringDefinition, monitoring: Monitoring, order: Order, onComplete: () => void) {
    if (!isSync) {
      this.offlineService.addMonitoringDataToStorage(monitoringData, monitoring, order);
      if (onComplete) {
        onComplete();
      }
    }
  }

  public updateMonitoringData(monitoringData: MonitoringDefinition, monitoring: Monitoring, order: Order, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      return this.monitoringService.updateMonitoringData(monitoringData).pipe(
        timeout(this.REQUEST_TIMEOUT),
        retry(2),
        catchError(error => {
          if (this.isErrorDueToConnectionIssues(error)) {
            if (!isSync) {
              this.offlineService.addMonitoringDataToStorage(monitoringData, monitoring, order);
            }
            return EMPTY;
          }
          this.onErrorNotNetworkRelated('Encountered issue when updating monitoring data: \n', error, monitoringData);
          return throwError(error);
        }),
        tap(() => this.offlineService.removeMonitoringDataFromStorage(monitoringData, monitoring, order))
      );
    } else if (!isSync) {
      this.offlineService.addMonitoringDataToStorage(monitoringData, monitoring, order);
    }
    return of(1);
  }

  public addPest(order: Order, newPest: Pest, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      const multilingualProperties = this.dataService.definitionsVM[TypeKey.pest].definitionVM.multilingualProperties;
      const listProperties = this.dataService.definitionsVM[TypeKey.pest].definitionVM.listProperties;
      const query = this.dataService.createPersistObject(newPest, multilingualProperties, listProperties);
      return this.persistenceService.addObjectForInsert(TypeKey.pest, query).pipe(
        timeout(this.REQUEST_TIMEOUT),
        retry(2),
        catchError(error => {
          if (this.isErrorDueToConnectionIssues(error)) {
            if (!isSync) {
              this.offlineService.addPestToStorage(order, newPest);
            }
            this.onErrorNotNetworkRelated('Encountered issue when adding pest: \n', error, newPest);
            return EMPTY;
          }
          return throwError(error);
        }),
        tap((assignedPest: Pest) => {
          order.pests.push(assignedPest.id);
          this.stateManagerService.updateObjectState('pest', assignedPest, false);
          this.offlineService.removePestFromStorage(order, assignedPest);
        })
      );
    } else if (!isSync) {
      this.offlineService.addPestToStorage(order, newPest);
    }
    return of(1);
  }

  public removePest(order: Order, removedPest: Pest) {
    let pestIndex: number;
    if (removedPest.id) {
      pestIndex = order.pests.indexOf(removedPest.id);
      if (pestIndex > -1) {
        order.pests.splice(pestIndex, 1);
        this.stateManagerService.refreshPests();
      }
    } else {
      this.offlineService.removePestFromStorage(order, removedPest);
    }
  }

  public createPestCatalog(ident: string, onComplete?: () => void, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      return this.dataService.createItem<PestCatalog>({ident: ident}, TypeKey.pestCatalog).pipe(
        timeout(this.REQUEST_TIMEOUT),
        retry(2),
        catchError(error => {
          if (this.isErrorDueToConnectionIssues(error)) {
            this.onOfflinePestCatalogCreation(isSync, ident, onComplete);
            return EMPTY;
          }
          this.onErrorNotNetworkRelated('Encountered issue when creating pest catalog: \n', error, { ident });
          return throwError(error);
        }),
        tap(() => {
          this.stateManagerService.updateObjectState('pestCatalog', {ident: ident});
          this.offlineService.removePestCatalogFromStorage(ident);
          if (onComplete) {
            onComplete();
          }
        })
      );
    } else {
      this.onOfflinePestCatalogCreation(isSync, ident, onComplete);
    }
    return of(1);
  }

  private onOfflinePestCatalogCreation(isSync: boolean, ident: string, onComplete: () => void) {
    if (!isSync) {
      this.offlineService.addPestCatalogToStorage(ident);
      onComplete();
    }
  }

  public uploadAttachment(order: Order, attachment?: string, offlineAttachment?: OfflineAttachment, isSync?: boolean): Observable<any> {
    const base64Attachment = attachment ?? offlineAttachment?.attachment;
    if (this.shouldPerformOnlineOperation(isSync)) {
      const imageToSend: Blob = base64StringToBlob(base64Attachment, 'image/jpg');
      return this.coreDataService.insertAsset(imageToSend, 'image.png')
        .pipe(
          timeout(this.REQUEST_TIMEOUT),
          catchError(error => {
            if (this.isErrorDueToConnectionIssues(error)) {
              if (!isSync) {
                this.offlineService.addAttachmentToStorage(order, base64Attachment);
              }
              this.onErrorNotNetworkRelated('Encountered issue when uploading attachment: \n', error, { attachment });
              return EMPTY;
            }
            return throwError(error);
          }),
          tap(savedAsset => {
            order.attachements.push(savedAsset.body.id);
            if (offlineAttachment) {
              this.offlineService.removeAttachmentFromStorage(order, offlineAttachment);
            }
            imageToSend.arrayBuffer().then(arrayBuffer => this.stateManagerService.updateAttachment(order.id, arrayBuffer));
          })
        );
    } else if (!isSync) {
      this.offlineService.addAttachmentToStorage(order, base64Attachment);
    }
    return of(1);
  }

  private isOfflineOrderUpToDate(order: Order): boolean {
    return !order.offlineState || (
      (!order.offlineState.servicesToModify || order.offlineState.servicesToModify.length === 0) &&
      (!order.offlineState.attachments || order.offlineState.attachments.length === 0) &&
      (!order.offlineState.pests || order.offlineState.pests.length === 0) &&
      !order.offlineState.monitoring && !order.offlineState.confirmSignature &&
      !order.offlineState.employeeConfirmSignature && !order.offlineState.customerSignature &&
      !order.offlineState.employeeSignature
    );
  }

  private isErrorDueToConnectionIssues(error: any): boolean {// Courtesy to https://daanstolp.nl/articles/2021/angular-pwa-2/#saving-data
    return (error instanceof TimeoutError) || (error.error instanceof ErrorEvent) || !this.internetService.isOnline;
  }

  public updateOrder(orderObject: Order, navigateTo?: string, finishedCallback?: () => void, isSync?: boolean): Observable<any> {
    if (this.shouldPerformOnlineOperation(isSync)) {
      const order: OrderViewModel = new OrderViewModel(orderObject);
      const query = this.dataService.prepareOrderObject(order);
      const multilingualProperties = this.dataService.definitionsVM[TypeKey.order].definitionVM.multilingualProperties;
      const listProperties = this.dataService.definitionsVM[TypeKey.order].definitionVM.listProperties;
      const object = this.dataService.createPersistObject(query, multilingualProperties, listProperties);
      const previousStatus = orderObject.status;
      if (!this.isOfflineOrderUpToDate(orderObject)) {
        if (orderObject.status !== 'NEU') {
          orderObject.status = 'NEU';
        }
      }
      return this.persistenceService.addObjectForUpdate(order.id, object).pipe(
        timeout(this.REQUEST_TIMEOUT),
        catchError(error => {
          orderObject.status = previousStatus;
          if (this.isErrorDueToConnectionIssues(error)) {
            if (!isSync) {
              this.offlineService.addOrderToStorage(orderObject, false, () => {
                if (finishedCallback) {
                  finishedCallback();
                }
              });
            }
            return EMPTY;
          }
          this.onErrorNotNetworkRelated('Encountered issue when updating order!\n', error, orderObject);
          return throwError(error);
        }),
        tap(() => {
          if (this.isOfflineOrderUpToDate(orderObject)) {
            this.offlineService.removeOrderFromStorage(orderObject);
            if (finishedCallback) {
              finishedCallback();
            }
          } else {
            orderObject.status = previousStatus;
            this.offlineService.updateStoredOrder(orderObject, () => {
              if (finishedCallback) {
                finishedCallback();
              }
            });
          }
          if (navigateTo) {
            this.router.navigate([navigateTo, order.id]).then();
          }
        })
      );
    } else if (!isSync) {
      this.offlineService.addOrderToStorage(orderObject, false, () => {
        if (finishedCallback) {
          finishedCallback();
        }
      });
    }
    return of(1);
  }

  private onErrorNotNetworkRelated(message: string, error?: any, object?: any): void {
    this.errorSubmissionService.submitAutomaticIssue(`${message}: ${ error?.message ?? 'No error message :('}`, object);
  }

}
