import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, ReplaySubject, Subscription } from "rxjs";
import {
  Address,
  ContactPerson,
  Customer,
  Monitoring,
  MonitoringDefinition,
  NavigationTours,
  Order,
  Pest,
  PestCatalog,
  Service,
  ServiceCatalog,
  VerminMeasure
} from "@wissenswerft/ibo-catalog-library";
import { DataDefinition } from "../models/customer-space.model";
import { CoreDataService, TypeKey } from "@wissenswerft/core/data";
import { TourService } from "./tour.service";
import { DataService } from "./data.service";
import { flatMap } from "../utils/arrays.utils";
import { AssetDataWrapper } from "../models/asset-data-wrapper.model";
import { ObjectAndDefinitions } from "../models/objects-and-definitions.model";
import { map, switchMap, take, tap } from "rxjs/operators";
import { Note } from "../models/note.model";
import { ProfileInfo } from "../models/profile-info.model";
import { AuthService } from "../providers/auth.service";
import { OfflineService } from "./offline/offline.service";
import { InternetService } from "./offline/internet.service";
import { DataDefinitionViewModel } from "../view-models/customer-space.view-model";
import { getNotesForOrder } from "./state.utils";
import { StorageService } from "./offline/storage.service";

@Injectable({
  providedIn: 'root'
})
// TODO: Switch to redux when more budget
// We need to load the data on app startup so that we can reuse it from here (it has to work with offline usage in cordova)
export class StateService implements OnDestroy {

  readonly ordersSubject: ReplaySubject<Order[]> = new ReplaySubject<Order[]>(1);

  orders: Observable<Order[]> = this.ordersSubject.asObservable();

  readonly tourSubject: ReplaySubject<NavigationTours> = new ReplaySubject<NavigationTours>(1);

  tour: Observable<NavigationTours> = this.tourSubject.asObservable();

  readonly profileInfoSubject: ReplaySubject<ProfileInfo> = new ReplaySubject<ProfileInfo>(1);

  profileInfo: Observable<ProfileInfo> = this.profileInfoSubject.asObservable();

  readonly serviceSubject: ReplaySubject<DataDefinition> = new ReplaySubject<DataDefinition>(1)

  serviceDataDefinition: Observable<DataDefinition> = this.serviceSubject.asObservable();

  readonly orderDataDefinitionSubject: ReplaySubject<DataDefinition> = new ReplaySubject<DataDefinition>(1);

  orderDataDefinition: Observable<DataDefinition> = this.orderDataDefinitionSubject.asObservable();

  readonly customersSubject: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);

  customers: Observable<Customer[]> = this.customersSubject.asObservable();

  readonly contactPersonsSubject: ReplaySubject<ContactPerson[]> = new ReplaySubject<ContactPerson[]>(1);

  contactPersons: Observable<ContactPerson[]> = this.contactPersonsSubject.asObservable();

  readonly servicesSubject: ReplaySubject<Service[]> = new ReplaySubject<Service[]>(1);

  services: Observable<Service[]> = this.servicesSubject.asObservable();

  readonly verminMeasuresSubject: ReplaySubject<VerminMeasure[]> = new ReplaySubject<VerminMeasure[]>(1);

  verminMeasures: Observable<VerminMeasure[]> = this.verminMeasuresSubject.asObservable();

  readonly customerSignaturesSubject: ReplaySubject<AssetDataWrapper[]> = new ReplaySubject<AssetDataWrapper[]>(1);

  customerSignatures: Observable<AssetDataWrapper[]> = this.customerSignaturesSubject.asObservable();

  readonly employeeSignatureSubject: ReplaySubject<AssetDataWrapper[]> = new ReplaySubject<AssetDataWrapper[]>(1);

  employeeSignatures: Observable<AssetDataWrapper[]> = this.employeeSignatureSubject.asObservable();

  readonly pestsSubject: ReplaySubject<ObjectAndDefinitions<Pest[]>> = new ReplaySubject<ObjectAndDefinitions<Pest[]>>(1);

  pestsAndDefinition: Observable<ObjectAndDefinitions<Pest[]>> = this.pestsSubject.asObservable();

  readonly pestCatalogsAndDefinitionSubject: ReplaySubject<ObjectAndDefinitions<PestCatalog[]>> = new ReplaySubject<ObjectAndDefinitions<PestCatalog[]>>(1);

  pestCatalogsAndDefinition: Observable<ObjectAndDefinitions<PestCatalog[]>> = this.pestCatalogsAndDefinitionSubject.asObservable();

  readonly attachmentsSubject: ReplaySubject<AssetDataWrapper[]> = new ReplaySubject<AssetDataWrapper[]>(1);

  attachments: Observable<AssetDataWrapper[]> = this.attachmentsSubject.asObservable();

  readonly notesSubject: ReplaySubject<Note[]> = new ReplaySubject<Note[]>(1);

  notes: Observable<Note[]> = this.notesSubject.asObservable();

  readonly confirmSignaturesSubject: ReplaySubject<AssetDataWrapper[]> = new ReplaySubject<AssetDataWrapper[]>(1);

  confirmSignatures: Observable<AssetDataWrapper[]> = this.confirmSignaturesSubject.asObservable();

  readonly employeeConfirmSignatureSubject: ReplaySubject<AssetDataWrapper[]> = new ReplaySubject<AssetDataWrapper[]>(1);

  employeeConfirmSignatures: Observable<AssetDataWrapper[]> = this.employeeConfirmSignatureSubject.asObservable();

  readonly monitoringDefinitionsSubject: ReplaySubject<ObjectAndDefinitions<MonitoringDefinition[]>> = new ReplaySubject<ObjectAndDefinitions<MonitoringDefinition[]>>(1);

  monitoringDefinitions: Observable<ObjectAndDefinitions<MonitoringDefinition[]>> = this.monitoringDefinitionsSubject.asObservable();

  readonly lastMonitoringsAndDefSubject: ReplaySubject<ObjectAndDefinitions<Monitoring[]>> = new ReplaySubject<ObjectAndDefinitions<Monitoring[]>>(1);

  lastMonitoringsAndDef: Observable<ObjectAndDefinitions<Monitoring[]>> = this.lastMonitoringsAndDefSubject.asObservable();

  readonly serviceCatalogsAndDefSubject: ReplaySubject<ObjectAndDefinitions<ServiceCatalog[]>> = new ReplaySubject<ObjectAndDefinitions<ServiceCatalog[]>>(1);

  serviceCatalogsAndDef: Observable<ObjectAndDefinitions<ServiceCatalog[]>> = this.serviceCatalogsAndDefSubject.asObservable();

  private subscriptions: Subscription[] = [];

  // Leftover from previous implementation. Should remove when possible
  private orderDetailSubject = new BehaviorSubject<Order>(null);

  public orderDetail$ = this.orderDetailSubject.asObservable();

  public currentOrder: Order;

  constructor(
    private authService: AuthService,
    private coreDataService: CoreDataService,
    private dataService: DataService,
    private tourService: TourService,
    private internetService: InternetService,
    private storageService: StorageService
  ) {
    if (this.authService.getAccessToken()) {
      console.log('[StateService]: Starting data initialization!');
      this.initializeData();
    } else {
      console.log('[StateService]: No access token, not initializing data!')
    }
  }

  public initializeData(): void {
    this.dataService.loadingVisible = true;
    const weekAgoDate = this.getWeekAgo();
    this.subscriptions.push(this.authService.getProfileInformation().subscribe(profileInfo => {
      this.profileInfoSubject.next(profileInfo);
      this.subscriptions.push(this.tourService.getRelevantTourWithFallback(profileInfo).subscribe(tour => this.tourSubject.next(tour)));
      this.subscriptions.push(this.coreDataService.getItemsBySpoqlQuery<Order[]>(
        'order',
        `{ property 'executionDate' after '${weekAgoDate.toISOString().split('T')[0]}' } and { property 'technician' eq ${profileInfo.id} } and {not {property 'lastLogStatus' eq 'inaktiv'}}`
      ).subscribe(orders => {
        this.replaceOrdersWithStoredOnes(orders).then(finalOrders => {
          this.loadOrderItems(finalOrders);
          this.ordersSubject.next(finalOrders);
          this.updateOrderDetailSubject(finalOrders);
          this.loadOrderDataInForeground(finalOrders);
        })
      }));
    }));
    this.subscriptions.push(this.coreDataService.getDefinitonByTypeKey<DataDefinition>(TypeKey.service).subscribe(serviceDefinition => {
      this.setServiceDefinition(serviceDefinition, TypeKey.service);
      this.serviceSubject.next(serviceDefinition);
    }));
    this.subscriptions.push(this.coreDataService.getDefinitonByTypeKey<DataDefinition>(TypeKey.order).subscribe(orderDefinition => {
      this.setServiceDefinition(orderDefinition, TypeKey.order);
      this.orderDataDefinitionSubject.next(orderDefinition);
    }));
    this.loadNonOrderDataInForeground();
  }

  private updateOrderDetailSubject(finalOrders: Order[]) {
    this.subscriptions.push(this.orderDetailSubject.pipe(
      take(1)
    ).subscribe(order => {
      if (order) {
        const updatedOrder = finalOrders.find(finalOrder => finalOrder.id === order.id);
        if (updatedOrder) {
          this.sendOrderDetail(updatedOrder);
          this.currentOrder = updatedOrder;
        }
      }
    }));
  }

  public sendOrderDetail(order: Order): void {
    this.orderDetailSubject.next(order);
  }

  private getWeekAgo(): Date {
    const weekAgoDate = new Date(new Date());
    weekAgoDate.setDate(weekAgoDate.getDate() - 7);
    return weekAgoDate;
  }

  private setServiceDefinition(definition: DataDefinition, typeKey: TypeKey) {
    this.dataService.definitionsVM[typeKey] = definition;
    this.dataService.definitionsVM[typeKey].definitionVM = new DataDefinitionViewModel(definition, {});
  }

  private replaceOrdersWithStoredOnes(orders: Order[]): Promise<Order[]> {
    const isOnline = this.internetService.isOnline;
    return this.storageService.getOrders().then(storedOrders => {
      const finalOrders: Order[] = [];
      orders.forEach(order => order.offlineState = {});
      orders.forEach(order => {
        const storedIndex = storedOrders.findIndex(storedOrder => storedOrder.id === order.id);
        if (storedIndex > -1) {
          if (!order.overwriteLocalStateWithBackend || !isOnline) {
            order = storedOrders[storedIndex];
          }
        }
        finalOrders.push(order);
      });
      const weekAgoTime = this.getWeekAgo().getTime();
      // We also filter by date here in case the service worker kicks in and fetches old results for us
      return finalOrders.filter(order => new Date(order.executionDate).getTime() > weekAgoTime);
    })
  }

  private loadNonOrderDataInForeground() {
    this.loadPestsCatalogsAndDefinition();
    this.loadServiceCatalogsAndDefinition();
  }

  private loadOrderItems(orders: Order[]): void { // Load all at once for performance & offline usage
    let loadedDataForSpinner = 0;
    this.subscriptions.push(this.dataService.getAllItemsByIds<Customer>('customer', orders.map(order => order.customer))
      .subscribe(customers => this.loadAddresses(customers), error => console.error(error), () => {
        loadedDataForSpinner ++;
        this.onDataLoad(loadedDataForSpinner);
      }));
    this.subscriptions.push(this.dataService.getAllItemsByIds<ContactPerson>('person', orders.map(order => order.contactPerson))
      .subscribe(contactPersons => this.contactPersonsSubject.next(contactPersons), error => console.error(error), () => {
        loadedDataForSpinner ++;
        this.onDataLoad(loadedDataForSpinner);
      }));
  }

  private loadAddresses(customers: Customer[]) {
    this.subscriptions.push(this.dataService.getAllItemsByIds<Address>('address', customers.map(customer => customer.address)).subscribe(
      addresses => {
        customers.forEach(customer => customer.fetchedAddress = addresses.find(address => customer.address === address.id));
        this.customersSubject.next(customers);
      }
    ));
  }

  private onDataLoad(loadedDateAmount: number) {
    if (loadedDateAmount === 2) {
      this.dataService.loadingVisible = false;
    }
  }

  private loadOrderDataInForeground(orders: Order[]) {
    this.loadServices(orders);
    this.loadVerminMeasures(orders);
    this.loadSignatures(orders);
    this.loadAttachments(orders);
    this.loadPestsAndDefinition(orders);
    this.loadNoteData(orders);
    this.loadMonitoringDefinitions(orders);
    this.loadLastMonitorings(orders);
  }

  private loadServices(orders: Order[]) {
    const totalServicesToFetch = [...orders.map(order => order.services), ...orders.map(order => order.plannedServices)];
    this.subscriptions.push(this.dataService.getAllItemsByIds<Service>('service', flatMap(totalServicesToFetch))
      .subscribe(services => this.servicesSubject.next(services)));
  }

  private loadVerminMeasures(orders: Order[]) {
    this.subscriptions.push(this.dataService.getAllItemsByIds<VerminMeasure>('verminMeasure', flatMap(orders.map(order => order.verminMeasures)))
      .subscribe(verminMeasures => this.verminMeasuresSubject.next(verminMeasures)));
  }

  private loadSignatures(orders: Order[]) {
    this.loadSignaturesForSubject(orders, 'customerSignature', this.customerSignaturesSubject);
    this.loadSignaturesForSubject(orders, 'employeeSignature', this.employeeSignatureSubject);
    this.loadSignaturesForSubject(orders, 'confirmSignature', this.confirmSignaturesSubject);
    this.loadSignaturesForSubject(orders, 'employeeConfirmSignature', this.employeeConfirmSignatureSubject);
  }

  private loadSignaturesForSubject(orders: Order[], signatureKey: string, replaySubjectToUse: ReplaySubject<AssetDataWrapper[]>) {
    const ordersThatHaveProperty = orders.filter(order => order[signatureKey]);
    if (ordersThatHaveProperty.length === 0) {
      replaySubjectToUse.next([]);
      return;
    }
    this.subscriptions.push(forkJoin(
      ordersThatHaveProperty.map(order => this.coreDataService.getAssetsByItemId<ArrayBuffer>(order[signatureKey])
          .pipe(
            map(asset => {
              return {
                arrayBuffer: asset,
                objectId: order.id
              }
            })
          )
        )
    ).subscribe(signatures => replaySubjectToUse.next(signatures)));
  }

  private loadAttachmentsForOrders(orders: Order[]) {
    if (!orders.find(order => order.attachements.length !== 0)) {
      this.attachmentsSubject.next([]);
      return;
    }
    this.subscriptions.push(forkJoin(
      flatMap(orders.filter(order => order.attachements && (order.attachements.length > 0)).map(order => this.getAttachmentRequestsForOrder(order)))
    ).subscribe(attachments => this.attachmentsSubject.next(attachments)));
  }

  private getAttachmentRequestsForOrder(order: Order): Observable<AssetDataWrapper>[] {
    return order.attachements.map(attachment =>//we pile the attachment requests
      this.coreDataService.getAssetsByItemId<ArrayBuffer>(attachment)
        .pipe(map(arrayBuffer => {
          return {
            objectId: order.id, arrayBuffer: arrayBuffer
          }
        }))
    );
  }

  private loadPestsAndDefinition(orders: Order[]) {
    this.subscriptions.push(forkJoin([
      this.dataService.getAllItemsByIds<Pest>('pest', flatMap(orders.map(order => order.pests))),
      this.coreDataService.getDefinitonByTypeKey<DataDefinition>(TypeKey.pest)
    ]).subscribe(responses => {
      this.setServiceDefinition(responses[1], TypeKey.pest);
      this.pestsSubject.next(
        {
          object: responses[0],
          dataDefinition: responses[1]
        }
      );
    }));
  }

  private loadAttachments(orders: Order[]) {
    this.loadAttachmentsForOrders(orders);
  }

  private loadPestsCatalogsAndDefinition() {
    this.subscriptions.push(this.dataService.getDefinitionAndData<PestCatalog[]>(TypeKey.pestCatalog)
      .subscribe(pestCatalogsAndDefinition => {
        this.setServiceDefinition(pestCatalogsAndDefinition[0], TypeKey.pestCatalog);
        this.pestCatalogsAndDefinitionSubject.next({
          dataDefinition: pestCatalogsAndDefinition[0],
          object: pestCatalogsAndDefinition[1]
        });
      }));
  }

  public getPestCatalogs(): Observable<ObjectAndDefinitions<PestCatalog[]>> {
    return this.pestCatalogsAndDefinitionSubject.pipe(
      switchMap(pestCatalogsAndDefinition => from(this.storageService.getPestCatalogs()).pipe(map(offlineCatalogs => {
        return { pestCatalogsAndDefinition, offlineCatalogs }
      }))),
      map(data => {
        const catalogs = [...data.pestCatalogsAndDefinition.object];
        const offlineCatalogs = data.offlineCatalogs
          .filter(offlineCatalog => catalogs.every(catalog => offlineCatalog !== catalog.ident))
          .map(ident => { return { ident } });
        catalogs.push(...offlineCatalogs);
        return { dataDefinition: data.pestCatalogsAndDefinition.dataDefinition, object: catalogs };
      })
    );
  }

  private loadServiceCatalogsAndDefinition() {
    this.subscriptions.push(this.dataService.getDefinitionAndData<ServiceCatalog[]>(TypeKey.serviceCatalog)
      .subscribe(serviceCatalogsAndDefinition => {
        this.setServiceDefinition(serviceCatalogsAndDefinition[0], TypeKey.serviceCatalog);
        this.serviceCatalogsAndDefSubject.next({
          dataDefinition: serviceCatalogsAndDefinition[0],
          object: serviceCatalogsAndDefinition[1]
        });
      }));
  }

  private loadNoteData(orders: Order[]) {
    this.subscriptions.push(forkJoin(this.getNoteRequestsForOrders(orders)).subscribe(notes => {
      const flattenedNotes = flatMap(notes);
      this.subscriptions.push(this.loadNotesAttachments(flattenedNotes).subscribe(() => this.notesSubject.next(flattenedNotes)));
    }));
  }

  // We fetch each order's notes but also the notes for orders in the 'idsForNotes' property, so we have to check that we don't do the same request multiple times
  private getNoteRequestsForOrders(orders: Order[]): Observable<Note[]>[] {
    const noteRequestsForOrder: { orderId: number, noteRequest: Observable<Note[]> }[] = [];
    orders.forEach(order => {
      // Own notes
      this.addNotesRequestForOrder(noteRequestsForOrder, order.id);
      // Extra notes
      order.idsForNotes.forEach(orderIdForNote => this.addNotesRequestForOrder(noteRequestsForOrder, orderIdForNote));
    });
    return noteRequestsForOrder.map(noteRequestWrapper => noteRequestWrapper.noteRequest);
  }

  private addNotesRequestForOrder(noteRequestsForOrder: { orderId: number; noteRequest: Observable<Note[]> }[], orderId: number) {
    const existingRequest = noteRequestsForOrder.find(request => request.orderId === orderId);
    if (!existingRequest) {
      noteRequestsForOrder.push({
        orderId: orderId,
        noteRequest: this.coreDataService.getNotesByItemId<Note[]>(orderId).pipe(
          tap(notes => notes.forEach(note => this.setHelperDataToNote(note, orderId))),
        )
      });
    }
  }

  private setHelperDataToNote(note: Note, orderId: number) {
    note.orderId = orderId;
    note.fetchedObjects = {attachments: []};
    note.offlineState = {};
  }

  private loadNotesAttachments(notes: Note[]): Observable<ArrayBuffer[]> {// load attachments and return the notes once done
    const notesWithAttachments = notes.filter(note => note.attachments.length > 0);
    if (notesWithAttachments.length === 0) {
      return of([]);
    }
    return forkJoin(
      notesWithAttachments.map(note =>
          this.coreDataService.getNoteImagesByItemId<ArrayBuffer>(note.id, note.attachments[0].uuid)
            .pipe(
              tap(attachment => note.fetchedObjects.attachments[0] = {arrayBuffer: attachment, objectId: note.id})
            )
        )
    );
  }

  private loadMonitoringDefinitions(orders: Order[]) {
    this.subscriptions.push(forkJoin([
      this.dataService.getAllItemsByIds<MonitoringDefinition>(
        'monitoringDefinition',
        flatMap(orders.map(order => flatMap(order.previousMonitorings.map(previousMonitoring => previousMonitoring.monitoringData))))
      ),
      this.coreDataService.getDefinitonByTypeKey<DataDefinition>(TypeKey.monitoringDefinition)
    ]).subscribe(data => {
      this.setServiceDefinition(data[1], TypeKey.monitoringDefinition);
      this.monitoringDefinitionsSubject.next({object: data[0], dataDefinition: data[1]})
    }));
  }

  private loadLastMonitorings(orders: Order[]) {
    this.subscriptions.push(forkJoin([
      this.coreDataService.getDefinitonByTypeKey<DataDefinition>(TypeKey.monitoring),
      ...orders.map(order => this.coreDataService.getItemsBySpoqlQuery<Monitoring[]>(
        "monitoring",
        `{property 'order' eq ${order.id}} limit 1`
      ))
    ]).subscribe(monitoringsAndDef => {
      const monitorings: Monitoring[] = monitoringsAndDef.length > 1 ? flatMap(monitoringsAndDef.splice(1) as Monitoring[][]) : [];
      monitorings.forEach(monitoring => {
        monitoring.fetchedObjects = {};
        monitoring.offlineState = {};
      });
      this.subscriptions.push(this.dataService.getAllItemsByIds<MonitoringDefinition>('monitoringDefinition', flatMap(monitorings.map(monitoring => monitoring.monitoringData)))
        .subscribe(definitions => {
          monitorings.forEach(monitoring => {
            monitoring.fetchedObjects.monitoringData = definitions.filter(definition => monitoring.monitoringData.includes(definition.id));
            monitoring.fetchedObjects.totalMonitoringData = monitoring.fetchedObjects.monitoringData;
          });
          this.setServiceDefinition(monitoringsAndDef[0] as DataDefinition, TypeKey.monitoring);
          this.lastMonitoringsAndDefSubject.next({
            object: monitorings,
            dataDefinition: (monitoringsAndDef[0] as DataDefinition)
          });
        }));
    }));
  }

  public dataFinishedLoading(): Observable<any> {
    return combineLatest([
      this.pestsAndDefinition,
      this.pestCatalogsAndDefinition,
      this.monitoringDefinitions,
      this.lastMonitoringsAndDef,
      this.serviceCatalogsAndDef
    ]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
