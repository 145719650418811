import { ProfileInfo } from "../../../../../apps/ibo-app/src/app/models/profile-info.model";

export class NamedItem {
    ident?: string;
    constructor() { }
}

export class PestCatalog extends NamedItem {
    id?: number;
    constructor() {
        super();
    }
}

export class Pest extends PestCatalog {
    infestationLevel?: string;
    odooVerminLevelId?: number;
    constructor() {
        super();
    }
}
export class ServiceCatalog {
    id: number;
    designation: string;
    description: string;
    unit: number;
    constructor(data) {
        this.id = data.id;
        this.designation = data.designation;
        this.description = data.description;
        this.unit = data.unit;
    }
}

export class Service {
    id?: number;
    plannedQuantity?: number;
    usedQuantity?: number;
    odooProductId?: number;
    odooLineId?: number;
    designation: string;
    description: string;
    unit: number;
    meta?: any;
}

export class Measure extends NamedItem {
    id: number;
    constructor() {
        super();
    }
}

export class ContactPerson extends NamedItem {
    id: number;
    name: string;
    telephone: string;
    mobile: string;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

export type SignatureType ='customer' | 'employee' | 'confirm' | 'employeeConfirm';

export class Order {
    id: number;
    offlineState: {
      customerSignature?: string;
      confirmSignature?: string;
      employeeSignature?: string;
      employeeConfirmSignature?: string;
      servicesToModify?: Service[];
      attachments?: OfflineAttachment[];
      monitoring?: Monitoring;
      pests?: Pest[];
    }
    orderNumber: string;
    idsForNotes: number[];
    customer: number;
    pests: number[];
    plannedServices: number[];
    performedServices: number[];
    attachements: number[];
    areas: string[];
    executionDate: string;
    contactPerson: number;
    technician: ProfileInfo;
    customerSignatureDate: Date;
    object: number;
    customerSignature: number;
    confirmSignature: number;
    employeeSignature: number;
    employeeConfirmSignature: number;
    verifiedOrderDate: Date;
    confirmOrderDate: Date;
    closedContractDate: Date;
    status: string;
    overwriteLocalStateWithBackend?: boolean;
    orderStateNote: string;
    petsOnSite: string[];
    furtherAppointmentsNeeded: string;
    measuresByIBO: string;
    causeOfInfestation: string;
    influencingFactors: string;
    measuresForClient: string;
    efficacyProvided: string;
    comments: any;
    protocolStatus: string;
    workTime: number;
    cpid: number;
    worklog: string;
    previousMonitorings: Monitoring[];
    appointmentId: number;
    historicMonitorings: string[];
    services: number[];
    customerName: string;
    jobDescription: string;
    closedOrderDescription: string;
    baitsRemoved: boolean;
    verminMeasures: number[];
    odooid: number;
    odooEmployeeId: number;
    saleOrderId: number;
    exported: boolean;
    other: string;
    employeeName: string;
    alreadyAccepted: boolean;
    customerNumber: string;
    lastLogStatus: string;
    lastLogTimestamp : string;
    participants : string;
    affectedAreas : string;
    constructor(data) {
        Object.assign(this, data.order);
    }
}

export class OfflineAttachment {// Needed so that we know which attachments have been uploaded and which haven't
  uuid: string;
  attachment: string;
}

export class AreaObject extends NamedItem {
    customer: Customer[];
    areas: Area[];
    address: Address;

    constructor(data) {
        super();
        (<any>Object).assign(this, data);
    }
}

export class Customer {
    id: number;
    customerNumber: string;
    name: string;
    address: number;
    fetchedAddress: Address;
    email: string;
    odooid: number;
    constructor(data) {
        Object.assign(this, data);
    }
}

export class Address {
    id: number;
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
    country: string;

    constructor(data) {
        Object.assign(this, data);
    }
}

export class Area {
    id: number;
    designation: string;

    constructor(designation: string) {
        this.designation = designation;
    }
}

export class MonitoringDefinition extends NamedItem {
    id: number;
    area: string;
    station: number;
    code: string;
    firstResult: string;
    secondResult: string;
    secondCheck: boolean;
    offlineId?: string;// Needed for when changes happen while in offline and the item hasn't been created
    constructor(data) {
        super();
        Object.assign(this, data)
    }
}

export class Monitoring extends NamedItem {
    id: number;
    order: number;
    monitoringData: number[];
    meta: any;
    fetchedObjects: {
      monitoringData?: MonitoringDefinition[]; // just the server side ones
      totalMonitoringData?: MonitoringDefinition[]; // all, including offline
    }
    offlineState: {
      monitoringData?: MonitoringDefinition[];
    }
    date: Date;
    effectiveness: string;
    customerSignatureName: string;
    employeeSignatureName: string;
    state: string;
    cpid: number;
    constructor(data) {
        super();
        Object.assign(this, data)
    }
}

export class VerminMeasure {
    id: number;
    pest: string;
    isControl: boolean;
    isCombat: boolean;
    isSetup: boolean;
    constructor(data) {
        Object.assign(this, data)
    }
}

export interface NavigationTours {
    startDate: Date;
    endDate: Date;
    graphmasterIdentifier: string;
    user: ProfileInfo
}

export enum EfficacyProvided {
    yes = 'yes',
    no = 'no',
    firstAppointment = 'firstAppointment'
}

export enum InfestationLevel {
    low = 'Niedrig',
    meium = 'Mittel ',
    high = 'Hosh'
}



