import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataService } from '../service/data.service';
import { DxPopupComponent } from 'devextreme-angular';
import { getNotesForOrder } from "../service/state.utils";
import { StateService } from "../service/state.service";
import { Order } from "@wissenswerft/ibo-catalog-library";
import { Note } from "../models/note.model";
import { OfflineCapableUpdaterService } from "../service/offline/offline-capable-updater.service";
import { ToastType } from "../models/toast.model";
import { StorageService } from "../service/offline/storage.service";
import { recordCrash } from "../utils/crashalytics.utils";

@Component({
  selector: 'note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss']
})
export class NoteListComponent implements OnInit, OnDestroy {

  @ViewChild('choicePopup') choicePopup: DxPopupComponent;

  private readonly title: string = "Notizen";

  private readonly base64Format: string = "data:image/png;base64,";

  public attachedImages = [];

  public subscriptions: Subscription[] = [];

  public notes: Note[] = [];

  public order: Order;

  constructor(
    private router: Router,
    private appService: AppService,
    public dataService: DataService,
    private stateService: StateService,
    private offlineCapableUpdaterService: OfflineCapableUpdaterService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.dataService.callLayoutTitle(this.title);

    this.subscriptions.push(this.stateService.orderDetail$.subscribe(order => {
      this.order = order;
      this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
        this.router.navigate([this.appService.layoutPath, this.stateService.currentOrder.id]).then();
      }));
      this.subscriptions.push(getNotesForOrder(this.stateService, this.storageService, this.order).subscribe(notes => {
        this.notes = [...notes];
        this.notes.filter(note => note.created).forEach(note => {
          note.created = note.created.substring(0, 10);
        });
        this.sortNotes();
      }));
    }));
  }

  private sortNotes() {
    this.notes.sort((note1, note2) => this.compareNotes(note1, note2));
  }

  private compareNotes(note1: Note, note2: Note): number {
    // Notes that are own first
    if (note1.orderId === this.order.id && note2.orderId !== this.order.id) {
      return -1;
    }
    if (note2.orderId === this.order.id && note1.orderId !== this.order.id) {
      return 1;
    }
    // New notes first
    if (note1.id && !note2.id) {
      return 1;
    }
    if (note2.id && !note1.id) {
      return -1;
    }
    return note2.id - note1.id;
  }

  public addNewNote = (): void => {
    this.router.navigateByUrl('note').then();
  }

  public openAssignedImages = (e): void => {
    this.attachedImages = [];
    const loadedAttachments = this.notes[e.itemIndex]?.fetchedObjects.attachments;
    if (loadedAttachments && loadedAttachments.length > 0 && loadedAttachments[0]) {
      const imageBase = this.base64Format + btoa(
        new Uint8Array(loadedAttachments[0].arrayBuffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      this.attachedImages.push(imageBase);
      this.choicePopup.instance.show();
    } else {
      this.choicePopup.instance.hide();
    }
  }

  public deleteNote = (e): void => {
    const note = this.notes[e.itemIndex];
    if (note.orderId !== this.order.id) {
      this.appService.callNotification({ message: 'Das ist eine Notiz aus einem vorherigen Auftrag. Sie kann nicht gelöscht werden.', type: ToastType.INFO });
      e.cancel = true;
    } else {
      this.subscriptions.push(
        this.offlineCapableUpdaterService.deleteNote(this.notes[e.itemIndex], this.order.id)
          .subscribe(() => {}, error => recordCrash("Error occurred while deleting note!", error))
      );
    }
  }

  public cancel = (): void => {
    this.choicePopup.instance.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
