import { Injectable, QueryList } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { CoreDataService, TypeKey } from '@wissenswerft/core/data';
import { DataDefinition } from '../models/customer-space.model';
import { AppService } from '../app.service';
import { DataDefinitionViewModel } from '../view-models/customer-space.view-model';
import { Address, ContactPerson, Customer, Measure, Monitoring, MonitoringDefinition, Order, PestCatalog, Service } from '@wissenswerft/ibo-catalog-library';
import { SignatureModel } from '../models/signature.model';
import { OrderViewModel } from '../view-models/order.view-model';
import { MonitoringDefinitionViewModel } from '../view-models/monitoring-definition.view-model';
import { DxButtonComponent } from 'devextreme-angular';


@Injectable()
export class DataService {

  assignMonitoringState: QueryList<DxButtonComponent> = new QueryList(null);

  private layoutTitleSubject: Subject<string> = new Subject<string>();

  public layoutTitle$ = this.layoutTitleSubject.asObservable();

  public definitionsVM: DataDefinitionViewModel[] = [];

  public isWorkTimeCalculated = false;

  public assignedService: Service;

  public currentAddress: Address;

  public currentMonitoring: Monitoring;

  public currentCustomer: Customer;

  public currentContactPerson: ContactPerson;

  public measures: Measure[];

  public pestCatalogs: PestCatalog[];

  public monitorings: MonitoringDefinition[];

  public monitoringColumns = [];

  public customerOrdersId = [];

  public signature: SignatureModel;

  public signatureProtocol: SignatureModel;

  public verifiedOrderDate: string;

  public loadingVisible: boolean = true;

  public plannedServices: Service[] = [];

  public servicesColumnsHeader = [
    {
      dataField: 'designation', caption: 'Bezeichnung', dataType: 'string', minWidth: 50, width: '60%'
    },
    {
      dataField: 'unit', caption: 'Einheit', dataType: 'string', minWidth: 50, width: '20%', visible: false
    }
  ];

  public currentMonitorings: MonitoringDefinitionViewModel[] = [];

  constructor(
    private dataService: CoreDataService,
    public appService: AppService
  ) { }

  public getDefinitionAndData<T>(typeKey: TypeKey): Observable<[DataDefinition, T]> {
    if (this.definitionsVM[typeKey]) {
      return forkJoin([of(this.definitionsVM[typeKey]), this.dataService.getItemsByName<T>(typeKey)]);
    }
    else {
      return forkJoin([this.dataService.getDefinitonByTypeKey<DataDefinition>(typeKey), this.dataService.getItemsByName<T>(typeKey)]);
    }
  }

  public createItem<T>(item: T, typeKey: string): Observable<T> {
    return this.dataService.createItem(typeKey, item);
  }

  public createPersistObject(data, multilingualProperties, listProperties) {
    const query = data;
    multilingualProperties.forEach(prop => {
      if (query.hasOwnProperty(prop.key)) {
        query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
      }
    });
    listProperties.forEach(prop => {
      if (query.hasOwnProperty(prop.key)) {
        if (!query[prop.key]?.length && query[prop.key].length != 0) {
          query[prop.key] = [query[prop.key]];
        }
      }
    });
    return query;
  }

  public getAllItemsByIds<T>(typeKey: string, ids: number[]): Observable<T[]> {
    if (ids.length === 0) {
      return of([]);
    }
    return this.dataService.getItemsBySpoqlQuery(
      typeKey,
      this.translateIdsToOneOfQuery(ids)
    );
  }

  private translateIdsToOneOfQuery(ids: number[]) {//oneof has a limit for parameters, so we have to split it
    const splitArrays: number[][] = [];
    const totalLength = ids.length;
    for (let i = 0; i < totalLength; i += 199) {
      splitArrays.push(ids.splice(0, 200));
    }
    if (ids.length > 0) {
      splitArrays.push(ids);
    }
    return splitArrays.filter(splitArray => splitArray.length !== 0)
      .map(splitArray => `{items id oneof ${splitArray.join(',')}}`)
      .join(" or ");
  }

  public callLayoutTitle(title: string): void {
    this.layoutTitleSubject.next(title);
  }

  public prepareSignatureDate(showTime: boolean): string {
    const today = new Date();
    if (showTime == true) {
      return today.getDay() + '.' + today.getMonth() + '.' + today.getFullYear() + '  ' + today.getHours() + ':' + today.getMinutes();
    } else {
      return today.getDay() + '-' + today.getMonth() + '-' + today.getFullYear();
    }
  }

  // This sucks and should be removed
  public prepareOrderObject(namedItem: OrderViewModel): Order {
    const query: Order = new Order({});
    query.orderNumber = namedItem.orderNumber;
    query.contactPerson = namedItem.contactPerson;
    query.customer = namedItem.customer;
    query.executionDate = namedItem.executionDate;
    query.pests = namedItem.pests;
    query.plannedServices = namedItem.plannedServices;
    query.performedServices = namedItem.performedServices;
    query.technician = namedItem.technician;
    query.customerSignature = namedItem.customerSignature;
    query.customerSignatureDate = namedItem.customerSignatureDate;
    query.confirmSignature = namedItem.confirmSignature;
    query.verifiedOrderDate = namedItem.verifiedOrderDate;
    query.confirmOrderDate = namedItem.confirmOrderDate;
    query.closedContractDate = namedItem.closedContractDate;
    query.status = namedItem.status;
    query.orderStateNote = namedItem.orderStateNote;
    query.attachements = namedItem.attachements;
    query.petsOnSite = namedItem.petsOnSite;
    query.measuresByIBO = namedItem.measuresByIBO;
    query.causeOfInfestation = namedItem.causeOfInfestation;
    query.influencingFactors = namedItem.influencingFactors;
    query.measuresForClient = namedItem.measuresForClient;
    query.furtherAppointmentsNeeded = namedItem.furtherAppointmentsNeeded;
    query.efficacyProvided = namedItem.efficacyProvided;
    query.workTime = namedItem.workTime;
    query.employeeSignature = namedItem.employeeSignature;
    query.employeeConfirmSignature = namedItem.employeeConfirmSignature;
    query.cpid = namedItem.cpid;
    query.protocolStatus = namedItem.protocolStatus;
    query.worklog = namedItem.worklog;
    query.previousMonitorings = namedItem.previousMonitorings;
    query.appointmentId = namedItem.appointmentId;
    query.services = namedItem.services;
    query.customerName = namedItem.customerName;
    query.jobDescription = namedItem.jobDescription;
    query.closedOrderDescription = namedItem.closedOrderDescription;
    query.baitsRemoved = namedItem.baitsRemoved;
    query.areas = namedItem.areas;
    query.verminMeasures = namedItem.verminMeasures;
    query.other = namedItem.other;
    query.employeeName = namedItem.employeeName;
    query.odooid = namedItem.odooid;
    query.odooEmployeeId = namedItem.odooEmployeeId;
    query.saleOrderId = namedItem.saleOrderId;
    query.exported = namedItem.exported;
    query.alreadyAccepted = namedItem.alreadyAccepted;
    query.customerNumber = namedItem.customerNumber;
    query.lastLogStatus = namedItem.lastLogStatus;
    query.lastLogTimestamp = namedItem.lastLogTimestamp;
    query.affectedAreas = namedItem.affectedAreas;
    query.participants = namedItem.participants;
    query.idsForNotes = namedItem.idsForNotes;
    query.object = namedItem.object;
    query.comments = namedItem.comments;

    return query;
  }

  public prepareMonitoringDefinitionQuery(monitoring: MonitoringDefinition): MonitoringDefinition {
    const query: MonitoringDefinition = new MonitoringDefinition({});
    query.ident = monitoring.ident
    query.firstResult = monitoring.firstResult;
    query.area = monitoring.area;
    query.station = monitoring.station;
    query.secondResult = monitoring.secondResult;
    query.code = monitoring.code;
    query.secondCheck = monitoring.secondCheck;
    return query;
  }

  public getDataType(dataType): string {
    switch (dataType) {
      case 'TEXT':
        return 'string';
      case 'NUMBER':
        return 'number';
      case 'LOCALDATE':
        return 'date';
      case 'BOOLEAN':
        return 'boolean';
      default:
        return 'string';
    }
  }

}
