import { Injectable } from '@angular/core';
import { StateService } from "./state.service";
import { take } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { AssetDataWrapper } from "../models/asset-data-wrapper.model";
import { Note } from "../models/note.model";
import { SignatureType } from "@wissenswerft/ibo-catalog-library";

@Injectable({
  providedIn: 'root'
})
export class StateManagerService {

  constructor(
    private stateService: StateService
  ) {
  }

  public updateAttachment(orderId: number, arrayBuffer: ArrayBuffer) {
    this.stateService.attachmentsSubject.pipe(
      take(1)
    ).subscribe(attachments => {
      attachments.push({objectId: orderId, arrayBuffer});
      this.stateService.attachmentsSubject.next(attachments);
    });
  }

  public updateNote(orderId: number, noteForOperation: Note, remove?: boolean) {
    this.stateService.notesSubject.pipe(
      take(1)
    ).subscribe(notes => {
      if (remove) {
        if (noteForOperation.id) {
          notes = notes.filter(note => note.id !== noteForOperation.id);
        }
        if (noteForOperation.offlineId) {
          notes = notes.filter(note => note.offlineId !== noteForOperation.offlineId)
        }
        if (!noteForOperation.id && !noteForOperation.offlineId) { // emergency fallback
          const noteIndex = notes.findIndex(note => (note.text !== noteForOperation.text) && (note.orderId !== noteForOperation.orderId));
          if (noteIndex > -1) {
            notes.splice(noteIndex, 1);
          }
        }
      } else {
        noteForOperation.orderId = orderId;
        if (noteForOperation.id) {
          const index = notes.findIndex(note => note.id === noteForOperation.id)
          if (index > -1) {
            notes[index] = noteForOperation
          } else {
            notes.push(noteForOperation);
          }
        } else {
          notes.push(noteForOperation);
        }
      }
      this.stateService.notesSubject.next(notes);
    });
  }

  public refreshSignatures(signatureType: SignatureType) {
    const subject = this.getSubjectForSignatureType(signatureType);
    subject.pipe(
      take(1)
    ).subscribe(value => subject.next(value));
  }

  public refreshServices() {
    this.stateService.services.pipe(
      take(1)
    ).subscribe(services => this.stateService.servicesSubject.next(services));
  }

  public refreshNotes() {
    this.stateService.notes.pipe(
      take(1)
    ).subscribe(notes => this.stateService.notesSubject.next(notes));
  }

  public refreshLatestMonitorings() {
    this.stateService.lastMonitoringsAndDef.pipe(
      take(1)
    ).subscribe(lastMonitoringsAndDef => this.stateService.lastMonitoringsAndDefSubject.next(lastMonitoringsAndDef));
  }

  public refreshPests() {
    this.stateService.pestsAndDefinition.pipe(
      take(1)
    ).subscribe(pests => this.stateService.pestsSubject.next(pests));
  }

  public refreshPestCatalogs() {
    this.stateService.pestCatalogsAndDefinition.pipe(
      take(1)
    ).subscribe(pestCatalogsAndDef => this.stateService.pestCatalogsAndDefinitionSubject.next(pestCatalogsAndDef));
  }

  public updateSignature(orderId: number, arrayBuffer: ArrayBuffer, signatureType: SignatureType) {
    const signaturesSubject = this.getSubjectForSignatureType(signatureType);
    signaturesSubject.pipe(
      take(1)
    ).subscribe(employeeSignatures => {
      let existingSignature = employeeSignatures.find(signature => signature.objectId === orderId);
      if (existingSignature) {
        existingSignature.arrayBuffer = arrayBuffer;
      } else {
        existingSignature = { objectId: orderId, arrayBuffer: arrayBuffer }
        employeeSignatures.push(existingSignature);
      }
      signaturesSubject.next(employeeSignatures);
    });
  }

  public updateObjectState(type: "pestCatalog" | "pest" | "services" | "monitoring", object?: any, removal?: boolean) {
    switch (type) {
      case "services":
        this.stateService.services.pipe(
          take(1)
        ).subscribe(services => {
          const existingServiceIndex = services.filter(service => service.id).findIndex(service => service.id === object.id);
          if (existingServiceIndex > -1) {
            services[existingServiceIndex] = object;
          } else {
            services.push(object);
          }
          this.stateService.servicesSubject.next(services);
        });
        break;
      case "monitoring": // We just trigger an update as the monitoring is already of the latest state
        this.stateService.lastMonitoringsAndDefSubject.pipe(
          take(1)
        ).subscribe(monitorings => {
          const changedMonitoringIndex = monitorings.object.findIndex(monitoring => monitoring.order === object.order);
          if (changedMonitoringIndex > -1) {
            monitorings.object[changedMonitoringIndex] = object;
          } else {
            monitorings.object.push(object);
          }
          this.stateService.lastMonitoringsAndDefSubject.next(monitorings);
        });
        break;
      case "pestCatalog":
        this.stateService.pestCatalogsAndDefinitionSubject.pipe(
          take(1)
        ).subscribe(pestCatalogsAndDefinitions => {
          pestCatalogsAndDefinitions.object.push(object);
          this.stateService.pestCatalogsAndDefinitionSubject.next(pestCatalogsAndDefinitions);
        });
        break;
      case "pest":
        this.stateService.pestsSubject.pipe(
          take(1)
        ).subscribe(pestAndDefinitions => {
          if (object) {
            if (removal) {
              let removalIndex: number;
              if ((typeof object) === "number") {
                removalIndex = pestAndDefinitions.object.findIndex(pest => pest.id !== object.id);
              } else {
                removalIndex = pestAndDefinitions.object.findIndex(pest => object.id ? (pest.id !== object.id) : (pest.ident !== object.ident || pest.infestationLevel !== object.infestationLevel));
              }
              if (removalIndex > -1) {
                pestAndDefinitions.object.splice(removalIndex, 1);
              }
            } else {
              pestAndDefinitions.object.push(object);
            }
          }
          this.stateService.pestsSubject.next(pestAndDefinitions);
        });
    }
  }

  private getSubjectForSignatureType(type: SignatureType): ReplaySubject<AssetDataWrapper[]> {
    switch (type) {
      case "confirm":
        return this.stateService.confirmSignaturesSubject;
      case "customer":
        return this.stateService.customerSignaturesSubject;
      case "employee":
        return this.stateService.employeeSignatureSubject;
      case "employeeConfirm":
        return this.stateService.employeeConfirmSignatureSubject;
    }
  }
}
