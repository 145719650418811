import { Injectable, Injector } from '@angular/core';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { Storage } from '@ionic/storage-angular';
import { Drivers } from "@ionic/storage";
import { Order } from "@wissenswerft/ibo-catalog-library";
import { StoredNote } from "./offline.service";
import { combineLatest, Observable } from "rxjs";
import { ErrorSubmissionService } from "./error-submission.service";


@Injectable({
  providedIn: 'root'
})
// Should move to actual sql queries for performance in the future
export class StorageService {

  public static readonly ORDERS_STORAGE_KEY = 'orders';

  public static readonly NOTES_TO_DELETE_KEY = 'notes_to_delete';

  public static readonly NOTES_TO_CREATE_KEY = 'notes_to_update';

  public static readonly PEST_CATALOGS_STORAGE_KEY = 'pest_catalogs';

  private storage: Storage;

  constructor(private injector: Injector) {}

  async init() {
    console.log('[StorageService]: Initializing SQLite!')
    this.storage = new Storage({
      name: 'iboDB',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
    });
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
  }

  public saveOrders(orders: Order[], callback?: () => void) {
    this.set(StorageService.ORDERS_STORAGE_KEY, orders).then(() => {
      if (callback) {
        callback();
      }
    }, error => this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while saving orders to db: ${error}`, orders));
  }

  public getOrders(): Promise<Order[]> {
    return this.get<Order[]>(StorageService.ORDERS_STORAGE_KEY).then(
      orders => orders ? orders : [],
      error => {
        this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while fetching orders from db: ${error}`);
        return [];
      }
    );
  }

  public getNotesToDelete(): Promise<StoredNote[]> {
    return this.get<StoredNote[]>(StorageService.NOTES_TO_DELETE_KEY).then(
      notes => notes ? notes : [],
      error => {
        this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while fetching notes to delete from db: ${error}`);
        return [];
      }
    );
  }

  public getNotesToCreate(): Promise<StoredNote[]> {
    return this.get<StoredNote[]>(StorageService.NOTES_TO_CREATE_KEY).then(
      notes => notes ? notes : [],
      error => {
        this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while fetching notes to create from db: ${error}`);
        return [];
      }
    );
  }

  public saveNotesToDelete(notes: StoredNote[], callback: () => void) {
    this.set(StorageService.NOTES_TO_DELETE_KEY, notes).then(
      callback,
      error => this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while saving notes to delete to db: ${error}`, notes)
    );
  }

  public saveNotesToCreate(notes: StoredNote[], callback: () => void) {
    this.set(StorageService.NOTES_TO_CREATE_KEY, notes).then(
      callback,
      error => this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while fetching notes to create to db: ${error}`, notes)
    );
  }

  public savePestCatalogs(pestCatalogs: string[], callback: () => void) {
    this.set(StorageService.PEST_CATALOGS_STORAGE_KEY, pestCatalogs).then(
      callback,
      error => this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while saving pest catalogs to db: ${error}`, pestCatalogs)
    );
  }

  public getPestCatalogs(): Promise<string[]> {
    return this.get<string[]>(StorageService.PEST_CATALOGS_STORAGE_KEY).then(
      catalogs => catalogs ? catalogs : [],
      error => {
        this.injector.get(ErrorSubmissionService).submitAutomaticIssue(`Encountered error while fetching pest catalogs to db: ${error}`);
        return [];
      });
  }

  public exportData(): Observable<any[]> {
    return combineLatest([
        this.getOrders(),
        this.getPestCatalogs(),
        this.getNotesToDelete(),
        this.getNotesToCreate()
      ]
    );
  }

  private set<T>(key: string, value: T): Promise<T> {
    return this.storage.set(key, value);
  }

  private get<T>(key: string): Promise<T> {
    return this.storage.get(key);
  }
}
