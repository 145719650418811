export function flatMap<T>(arrayOfArrays: T[][]): T[] {
  const flattenedArray: T[] = [];
  if (!arrayOfArrays) {
    return flattenedArray;
  }
  arrayOfArrays.forEach(array => {
    if (array) {
      array.forEach(element => flattenedArray.push(element));
    }
  });
  return flattenedArray;
}
