import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService, TypeKey } from "./data.service";
import { environment } from "../../../../../apps/ibo-app/src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {

    constructor(private dataService: DataService) {}

    public addObjectForInsert(typeKey: TypeKey, query): Observable<any> {
        return this.dataService.executeWebApiPostMethod(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/items/${typeKey}`, query);
    }

    public addObjectForUpdate(id: number, query): Observable<any> {
        return this.dataService.executeWebApiPutMethod(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/item/${id}`, query);
    }

    public addNote(query, id): Observable<any> {
        return this.dataService.executeWebApiPostMethod(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/item/${id}/notes`, query);
    }

}
