import { Input, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { DxToastComponent } from 'devextreme-angular';
import { IToast, ToastType } from '../models/toast.model';

@Component({
  selector: 'ww-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
    @ViewChild('toast') dxToast : DxToastComponent;
    @Input() type: ToastType = ToastType.INFO;
    @Input() isVisible: boolean = false;
    @Input() position: any = { at: 'top center', my: 'top center', offset: '0 60' };
    @Input() displayTime: number = 3000;
    private _message;
    @Input()
    get message() { return this._message; }
    set message(message) {
        this._message = message;
    }
    constructor() { }

    ngOnInit(): void {
        if(this.dxToast){
            this.dxToast.animation = undefined;
        }
    }

    public showNotification(notification: IToast): void {
        this.dxToast.message = notification.message;
        this.dxToast.type = notification.type;
        this.dxToast.displayTime = (notification.type === ToastType.ERROR) ? 8000 : 3000;
        this.dxToast.visible = true;
    }

}



