import { Injectable } from '@angular/core';
import { Service } from "@wissenswerft/ibo-catalog-library";
import { PersistenceService, TypeKey } from "@wissenswerft/core/data";
import { StateManagerService } from "../state-manager.service";
import { AppService } from "../../app.service";
import { DataService } from "../data.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ServiceProductService {

  constructor(
    private stateManagerService: StateManagerService,
    private appService: AppService,
    private dataService: DataService,
    private persistenceService: PersistenceService
  ) {}

  public updatePerformedService(service: Service) {
    const multilingualProperties = this.dataService.definitionsVM[TypeKey.service].definitionVM.multilingualProperties;
    const listProperties = this.dataService.definitionsVM[TypeKey.service].definitionVM.listProperties;
    delete service.meta;
    const query = this.dataService.createPersistObject(service, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.service, query);
  }

  public updateService(service: Service): Observable<any> {
    const multilingualProperties = this.dataService.definitionsVM[TypeKey.service].definitionVM.multilingualProperties;
    const listProperties = this.dataService.definitionsVM[TypeKey.service].definitionVM.listProperties;
    const object = this.dataService.createPersistObject(service, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForUpdate(service.id, object);
  }

}
