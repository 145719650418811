import {
  Component,
  EventEmitter,
  Input, OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ConvertHelper } from '@wissenswerft/core/data';
import { MonitoringDefinition, Order } from '@wissenswerft/ibo-catalog-library';
import { DxButtonComponent, DxDataGridComponent, DxListComponent } from 'devextreme-angular';
import { MonitoringDefinitionViewModel } from '../../view-models/monitoring-definition.view-model';
import { Status } from '../../view-models/order.view-model';
import { MONITORING_CODES } from "../../utils/monitoring.utils";

@Component({
  selector: 'monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {

  @ViewChildren('assignMonitoringButton') assignMonitoringButton: QueryList<DxButtonComponent>;

  @ViewChildren('checkedMonitoringButton') checkedMonitoringButton: QueryList<DxButtonComponent>;

  @ViewChild('monitoringTable') monitoringGrid: DxDataGridComponent;

  @ViewChild('monitoringHistory') monitoringHistory: DxListComponent;

  @Input('monitoringData') monitoringData: MonitoringDefinitionViewModel[] = [];

  @Input('assignedMonitoringList') assignedMonitoringList: MonitoringDefinition[] = [];

  @Input('order') order: Order;

  @Output() onRowUpdate = new EventEmitter();

  public monitoringColumns = [];

  private resultColumn = [
    { name: 'OB (ohne Befall)', value: 'OB' },
    { name: 'NA (nicht angenommen)', value: 'NA' },
    { name: 'UzB (unzugänglicher Bereich)', value: 'UZB' },
    { name: 'BG (Deutsche Schabe)', value: 'BG' },
    { name: 'BO (Orientalische Schabe)', value: 'BO' }
  ];

  private resultPlaceHolder = "Ergebnis in Prozent % eingeben...";

  public monitoring: MonitoringDefinition = new MonitoringDefinition({});

  public convertHelper = ConvertHelper;

  private assignedMonitoring = new MonitoringDefinition({});

  private checkedItemsIndexes = [];

  public ngOnInit() {
    this.initializeColumns();
  }

  public assginNewDate(itemData) {
    this.assignMonitoringButton.toArray().map((element, index) => {
      if (index == itemData.rowIndex) {
        const checked = this.checkedItemsIndexes[index];
        if (checked === undefined || checked === false) {
          this.checkedItemsIndexes[index] = true;
          element.icon = "assets/images/monitoringChecked.svg";
          this.assignedMonitoring = new MonitoringDefinition({});
          this.assignedMonitoring.area = itemData.key[0];
          this.assignedMonitoringList.push(this.assignedMonitoring);
        } else {
          this.checkedItemsIndexes[index] = false;
          this.assignedMonitoringList.map((element, checkedElementIndex) => {
            if (element.area == itemData.key[0]) {
              this.assignedMonitoringList.splice(checkedElementIndex, 1);
            }
          })
          element.icon = "assets/images/check01.svg";
        }
      }
    })
  }

  public rowPrepared(e) {
    e.rowElement.style.height = '40px';
  }

  public rowClick(e) {
    this.monitoring.area = e.key[0];
  }

  public rowUpdated(event) {
    this.onRowUpdate.emit(event);
  }

  public onGridCellPrepared(event) {
    if (event.column?.dataField === 'secondCheck') {
      if (event?.value === true) {
        event.text = '';
        event.cellElement.style.backgroundColor = 'red';
        event.cellElement.style.color = 'red';
      } else {
        event.cellElement.style.color = 'white';
      }
    }
    if (event.rowType == "header") {
      event.cellElement.style.textAlign = "left";
    }
    if (event.rowType == "data")
      event.cellElement.style.textAlign = "left";
  }

  onEditorPreparing(e) {
    if (e.dataField === "secondResult" || e.dataField === "firstResult") {
      Object.assign(e.editorOptions, {
        acceptCustomValue: true,
        onCustomItemCreating: args => {
          const newItem = {
            name: args.text,
            value: args.text
          };
          args.customItem = new Promise((resolve, reject) => {
            const component = args.component;
            const dataSource = component.getDataSource();
            const dataStore = dataSource.store();
            dataStore.insert(newItem).then(r => {
              dataSource.load();
              e.component.columnOption(e.dataField).lookup.update();
              resolve(newItem);
            });
          });
        }
      });
    }
  }

  public loadPreviousMonitoring(): void {
    const groupByColumn: Object = {
      dataField: 'groupByDate',
      caption: 'Monitoring Datum',
      dataType: 'text',
      groupIndex: 0,
      visible: true,
      allowEditing: false,
    };
    this.monitoringGrid.instance.addColumn(groupByColumn);
    this.monitoringGrid.instance.columnOption(0, 'allowEditing', false);
    this.monitoringGrid.instance.columnOption(1, 'allowEditing', false);
    this.monitoringGrid.instance.columnOption(2, 'allowEditing', false);
    this.monitoringGrid.instance.columnOption(3, 'allowEditing', false);
    this.monitoringGrid.instance.columnOption(4, 'allowEditing', false);
  }

  public loadCurrentMonitoring(): void {
    this.monitoringGrid.instance.deleteColumn('groupByDate');
    this.monitoringGrid.instance.columnOption(3, 'allowEditing', (!(this.order.status === Status.Erledigt || this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG)));
    this.monitoringGrid.instance.columnOption(4, 'allowEditing', (!(this.order.status === Status.Erledigt || this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG)));
  }

  public cellPrepared(event): void {
    if (event.column.dataField === "area") {
      event.cellElement.style.height = 100
      event.cellElement.style.whiteSpace = "normal"
      event.cellElement.style.overflowWrap = 'break-word'
    }
  }

  private initializeColumns() {
    this.monitoringColumns = [
      {
        dataField: 'station',
        caption: 'Station',
        dataType: 'number',
        visible: true,
        allowEditing: (!(this.order.status === Status.Erledigt || this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG)),
        allowFiltering: false,
        sortOrder: 'asc',
        width: '10%'
      },
      {
        dataField: 'code',
        caption: 'Code',
        dataType: 'text',
        visible: true,
        allowEditing: (!(this.order.status === Status.Erledigt || this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG)),
        width: '10%',
        customizeText: text => {
          if (text.valueText.includes("(") === true) {
            return text.valueText.substring(0, text.valueText.indexOf("("));
          } else if (text.valueText.includes("-") === true) {
            return text.valueText.substring(0, text.valueText.indexOf("-"));
          }
          else {
            return text.valueText;
          }
        },
        calculateDisplayValue: function (rowData) {
          return rowData.code;
        },
        lookup: {
          dataSource: MONITORING_CODES,
          showCancelButton: false,
          displayExpr: 'name',
          valueExpr: 'value'
        },
      },
      {
        dataField: 'area',
        caption: 'Bereich',
        dataType: 'text',
        visible: true,
        allowEditing: (!(this.order.status === Status.Erledigt || this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG)),
        width: '25%'
      },
      {
        dataField: 'firstResult',
        caption: 'Köder 1',
        dataType: 'text',
        visible: true,
        allowEditing: (!(this.order.status === Status.Erledigt || this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG)),
        width: '30%',
        customizeText: text => {
          if (text.valueText.includes("(") === true) {
            return text.valueText.substring(0, text.valueText.indexOf("("));
          }
          else {
            return text.valueText;
          }
        },
        placeholder: this.resultPlaceHolder,
        calculateDisplayValue: function (rowData) {
          return rowData.firstResult;
        },
        lookup: {
          dataSource: this.resultColumn,
          showCancelButton: false,
          displayExpr: 'name',
          valueExpr: 'value'
        },
      },
      {
        dataField: 'secondResult',
        caption: 'Köder  2',
        dataType: 'text',
        visible: true,
        allowEditing: (!(this.order.status === Status.Erledigt || this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG)),
        width: '30%',
        placeholder: this.resultPlaceHolder,
        calculateDisplayValue: function (rowData) {
          return rowData.secondResult;
        },
        customizeText: text => {
          if (text.valueText.includes("(") === true) {
            return text.valueText.substring(0, text.valueText.indexOf("("));
          }
          else {
            return text.valueText;
          }
        },
        lookup: {
          dataSource: this.resultColumn,
          showCancelButton: false,
          displayExpr: 'name',
          valueExpr: 'value'
        },
      },
      {
        dataField: 'secondCheck',
        caption: '',
        dataType: 'string',
        visible: true,
        allowEditing: false,
        allowFiltering: false,
        width: 20
      }
    ]
  }

}
