import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics";
import { fromError } from "stacktrace-js";

function recordIssueWithRecording(error, message: string) {
  FirebaseCrashlytics.recordException(
    { message: `An exception was thrown while recording the exception for the following message: ${message}.
                       Error message was: ${ error.message ?? 'No error message :('}`}
  ).then();
}

export function recordCrash(message: string, error?) {
  try {
    if (!error) {
      FirebaseCrashlytics.recordException({message}).then();
      return;
    }
    fromError(error).then(stacktrace =>
      FirebaseCrashlytics.recordException({
        message: `${message} Error message: ${error.message ?? 'No error message!'}`,
        stacktrace
      }), () => recordIssueWithRecording(error, message)
    ).then();
  } catch (exception) {
    recordIssueWithRecording(error, message);
  }
}
