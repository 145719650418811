import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../../../apps/ibo-app/src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) {}

  public getNotesByItemId<T>(id): Observable<T> {
    return this.executeWebApiGetMethod<T>(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/item/${id}/notes`);
  }

  public deleteNoteByItemId<T>(id): Observable<T> {
    return this.executeWebApiDeleteMethod(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/note/${id}`);
  }

  public getNoteImagesByItemId<T>(postingid, attachmentuid): Observable<T> {
    return this.executeImageWebApiGetMethod<T>(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/note/${postingid}/attachments/${attachmentuid}/thumbnail.png`);
  }

  public getAssetsByItemId<T>(id): Observable<T> {
    return this.executeImageWebApiGetMethod<T>(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/asset/${id}/thumbnail.png`);
  }

  public getItemsByName<T>(typeKey: TypeKey): Observable<T> {
    return this.executeWebApiGetMethod<T>(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/items/${typeKey}`);
  }

  public getDefinitonByTypeKey<T>(typekey: TypeKey): Observable<T> {
    return this.executeWebApiGetMethod<T>(`${environment.BACKEND_URL}/api/scope/${environment.SCOPE_KEY}/items/type/${typekey}`);
  }

  public getItemsBySpoqlQuery<T>(queryTable, whereCondition): Observable<T> {
    return this.executeWebApiPostMethod<T>(`${environment.BACKEND_URL}/api/spoql`, { 'q': this.getSPOQLQuery(environment.SCOPE_KEY, queryTable, whereCondition) });
  }

  private executeWebApiGetMethod<T>(url: string, params?: any): Observable<T> {
    if (sessionStorage.getItem('fixedCulture')) {
      const headers = new HttpHeaders().set('Accept-Language', sessionStorage.getItem('fixedCulture'));
      return this.http.get<T>(url, { headers: headers, params: params });
    } else {
      return this.http.get<T>(url, { params: params });
    }
  }

  private executeImageWebApiGetMethod<T>(url: string, params?: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'image/jpg');
    return this.http.get(url, { headers, responseType: 'arraybuffer' });
  }

  public insertAsset(value: Blob, fileName): Observable<HttpResponse<{ id: number }>> {
    const req = new FormData();
    req.append('assets', new Blob([JSON.stringify({
      language: "de",
      title: fileName,
      hidden: false,
      source: {
        location: 'mpr:image',
        filename: fileName
      }
    })], {
      type: 'application/json'
    }));
    req.append('image', value, fileName);
    return this.http.post<{ id: number }>(`${environment.BACKEND_URL}/api/scope/ibo/assets`, req,
      {
        observe: 'response',
        reportProgress: true
      }
    );
  }

  public insertNote(id, text, value?, fileName?): Observable<any> {
    const data = new FormData();
    data.append('notes', new Blob([JSON.stringify({
      text: text,
      attachments: [{
        name: fileName,
        type: "IMAGE",
        source: "mpr:image"
      }]
    })], {
      type: "application/json"
    }));
    data.append("image", value);
    return this.http.post(`${environment.BACKEND_URL}/api/scope/ibo/item/${id}/notes`, data);
  }

  private getSPOQLQuery(scope, selectTable, whereCondition) {
    const query = `at '${scope}' select item from '${selectTable}'`;
    if (whereCondition) {
      return  query + ` where ${whereCondition}`;
    }
    return query;
  }

  public executeWebApiPostMethod<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }

  public createItem<T>(typeKey: string, body: T): Observable<T> {
    return this.http.post<T>(`${environment.BACKEND_URL}/api/scope/ibo/items/${typeKey}`, body);
  }

  public executeWebApiDeleteMethod(url): Observable<any> {
    return this.http.delete(url);
  }

  public executeWebApiPutMethod(url, body): Observable<any> {
    return this.http.put(url, body);
  }

}

export enum TypeKey {
  module = 'module',
  riskAssessment = 'riskIssue',
  riskAssessmentMeasure = 'riskAssessmentMeasure',
  subModule = 'subModule',
  measure = 'measure',
  measureBenefit = 'measureBenefit',
  measureType = 'typeOfMeasure',
  measureClassification = 'measureClassification',
  measureTask = 'measureTask',
  measureProgress = 'measureProgress',
  measureDefinition = 'measureDefinition',
  standard = 'standard',
  responsiblePlan = 'responsiblePlan',
  staffMember = 'staffMember',
  riskReduction = 'riskReduction',
  damageKind = 'damageKind',
  country = 'country',
  tag = 'tag',
  product = 'product',
  project = 'project',
  legalBasis = 'legalBasis',
  order = 'order',
  person = 'person',
  service = 'service',
  company = 'company',
  pest = 'pest',
  monotoring = 'monotoring',
  customer = 'customer',
  area = 'area',
  object = 'object',
  serviceCatalog = 'serviceCatalog',
  pestCatalog = 'pestCatalog',
  address = 'address',
  subProject = 'subProject',
  event = 'event',
  chance = 'chance',
  decision = 'decision',
  monitoring = 'monitoring',
  monitoringDefinition = 'monitoringDefinition',
  tour = "tour",
  resource = "resource",
  role = "role",
  collaborator = "collaborator",
  errorSubmission = "errorSubmission"
}
