import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxPopupComponent, DxTextAreaComponent, DxTextBoxComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { CapacitorService } from '../service/capacitor.service';
import { DataService } from '../service/data.service';
import { Note } from '../models/note.model';
import { ToastType } from '../models/toast.model';
import { OfflineCapableUpdaterService } from "../service/offline/offline-capable-updater.service";
import { Photo } from "@capacitor/camera";
import { recordCrash } from "../utils/crashalytics.utils";
import { StateService } from "../service/state.service";

@Component({
  selector: 'note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit, OnDestroy {

  @ViewChild('choicePopup') choicePopup: DxPopupComponent;

  @ViewChild('title') titleField: DxTextBoxComponent;

  @ViewChild('note') noteField: DxTextAreaComponent;

  private readonly title: string = "Notiz";

  private readonly base64Format: string = "data:image/png;base64,";

  private subscriptions: Subscription[] = [];

  public attachedImages = [];

  public noteTitle: string;

  public content: string;

  public note: Note = new Note(null);

  private imageUrl: string;

  constructor(
    private dataService: DataService,
    private capacitorService: CapacitorService,
    private stateService: StateService,
    private router: Router,
    private appService: AppService,
    private zone: NgZone,
    private offlineCapableUpdaterService: OfflineCapableUpdaterService
  ) { }

  ngOnInit() {
    this.dataService.callLayoutTitle(this.title);
    this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
      this.router.navigateByUrl('noteList').then();
    }));
  }

  public addNote = (): void => {
    this.note.text = this.content;
    if (this.note.text != undefined) {
      this.note.orderId = this.stateService.currentOrder.id;
      this.subscriptions.push(this.offlineCapableUpdaterService.createNote(this.note, this.stateService.currentOrder.id, this.imageUrl, false)
        .subscribe(() => {}, error => recordCrash("Error occurred while adding note!", error)));
    } else {
      this.appService.callNotification({ message: 'Bitte geben Sie eine Notiz ein', type: ToastType.WARNING });
      this.router.navigateByUrl('noteList').then();
    }
  }

  public takePicture = (useGallery: boolean): void => {
    this.choicePopup.instance.hide();
    this.capacitorService.takePicture((photo: Photo) => {
      this.zone.run(() => {
        this.imageUrl = photo.base64String
        const image = new Image();
        image.src = this.base64Format + this.imageUrl;
        image.height = 200;
        image.width = 200;
        this.attachedImages.splice(0, 0, image.src);
      });
    }, useGallery);
  }

  public clearFields = (): void => {
    this.titleField.instance.reset();
    this.noteField.instance.reset();
  }

  public openWindow = (): void => {
    this.choicePopup.instance.show();
  }

  public cancel = (): void => {
    this.choicePopup.instance.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}
