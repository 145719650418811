import { MonitoringDefinition } from "@wissenswerft/ibo-catalog-library";

export class MonitoringDefinitionViewModel {
    protected monitoring: MonitoringDefinition;

    private _groupByDate: string;

    get id(): number { return this.monitoring.id; }

    get ident(): string { return this.monitoring.ident; }

    get area(): string { return this.monitoring.area; }

    get station(): number { return this.monitoring.station; }

    get firstResult(): string { return this.monitoring.firstResult; }

    get secondResult(): string { return this.monitoring.secondResult; }

    get code(): string { return this.monitoring.code; }

    get secondCheck(): boolean { return this.monitoring.secondCheck; }

    get groupByDate(): string { return this._groupByDate; }

    set area(value) { this.monitoring.area = value; }

    set groupByDate(value) { this._groupByDate = value; }

    set code(value: string) { this.monitoring.code = value }

    set firstResult(value: string) { this.monitoring.firstResult = value }

    set secondResult(value: string) { this.monitoring.secondResult = value }

    set station(value: number) { this.monitoring.station = value }

    set secondCheck(value: boolean) { this.monitoring.secondCheck = value }

    constructor(data: MonitoringDefinition) {
        this.monitoring = data;
    }
}

export enum MonitoringIcon {
    checked = 'assets/images/monitoringChecked.svg',
    unChecked = 'assets/images/check01.svg'
}

export const VISIBLE_PROPERTIES = [
    'ident', 'date', 'area', 'status'
];