import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private endpointsToSkip = ['token', 'renew'];

  constructor(private router: Router, private authenticationService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.endpointsToSkip.find(endpoint => request.url.endsWith(endpoint))) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.authenticationService.logout();
          }
          return throwError(error);
        })
      );
    }
    const isSecondTry = request.params.get('is-second-try');
    if (isSecondTry) {
      request = request.clone({params: request.params.delete('is-second-try')});
    }
    const token = this.authenticationService.getAccessToken();
    if (token) {
      request = request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 || error.status === 403 || error.status === 200) && !isSecondTry) {
          return this.authenticationService.renewTokenAnRetryRequest(request);
        }
        return throwError(error);
      })
    );
  }

}
