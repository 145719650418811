import { Injectable } from '@angular/core';
import {
  Monitoring,
  MonitoringDefinition, OfflineAttachment,
  Order,
  Pest, Service,
  SignatureType
} from "@wissenswerft/ibo-catalog-library";
import { StateManagerService } from "../state-manager.service";
import { signatureTypeToFieldName } from "../../utils/signature.utils";
import { Note } from "../../models/note.model";
import { findMonitoringDataIndex, findOfflineServiceIndex, findPestIndex } from "../../utils/offline.utils";
import { v4 as uuidv4 } from 'uuid';
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor(
    private stateManagerService: StateManagerService,
    private storageService: StorageService
  ) { }

  public addSignatureOffline(order: Order, signature: string, signatureType: SignatureType) {
    order.offlineState[signatureTypeToFieldName(signatureType)] = signature;
    this.addOrderToStorage(order, false, () => this.stateManagerService.refreshSignatures(signatureType));
  }

  public updateStoredOrder(order: Order, callback?: () => void) {
    this.addOrderToStorage(order, true, callback);
  }

  public addOrderToStorage(order: Order, onlyForExisting?: boolean, callback?: () => void) {
    this.storageService.getOrders().then(orders => {
      const index = orders.findIndex(storedOrder => storedOrder.id === order.id);
      if (index > -1) {
        orders[index] = order;
      } else {
        if (onlyForExisting) {
          callback();
          return;
        }
        orders.push(order);
      }
      this.storageService.saveOrders(orders, callback);
    });
  }

  public removeOrderFromStorage(order: Order) {
    this.storageService.getOrders().then(orders => {
      const index = orders.findIndex(storedOrder => storedOrder.id === order.id);
      if (index > -1) {
        orders.splice(index, 1);
      }
      this.storageService.saveOrders(orders);
    })
  }

  public addNoteForDeletion(note: Note, orderId: number) {
    this.storageService.getNotesToDelete().then(notesToDelete => {
      if (notesToDelete.every(noteToDelete => noteToDelete.note.id !== note.id)) {
        notesToDelete.push({ note, orderId });
        this.storageService.saveNotesToDelete(notesToDelete, () => this.stateManagerService.refreshNotes());
      }
    });
  }

  public removeNoteFromDeleted(note: Note, orderId: number, callback: () => void) {
    if (note.id) {
      this.removeNoteFromStoredNotes(noteWrapper => noteWrapper.note.id === note.id, 'delete', callback);
    } else { // Should match by text and order id
      this.removeNoteFromStoredNotes(noteWrapper => this.notesMatch(noteWrapper, note, orderId), 'delete', callback);
    }
  }

  private removeNoteFromStoredNotes(noteMatcher: (StoredNote) => boolean, type: 'delete' | 'create', callback: () => void) {
    (type === 'delete' ? this.storageService.getNotesToDelete() : this.storageService.getNotesToCreate()).then(notes => {
      const index = notes.findIndex(noteWrapper => noteMatcher(noteWrapper));
      if (index > -1) {
        notes.splice(index, 1);
      }
      (type === 'delete' ? this.storageService.saveNotesToDelete(notes, callback) : this.storageService.saveNotesToCreate(notes, callback));
    })
  }

  public removeNoteFromCreated(note: Note, orderId: number, callback: () => void) {
    this.removeNoteFromStoredNotes(noteWrapper => this.notesMatch(noteWrapper, note, orderId), 'create', callback);
  }

  private notesMatch(noteWrapper, note: Note, orderId: number) {
    if (note.offlineId) {
      return noteWrapper.offlineId === note.offlineId;
    }
    return (noteWrapper.note.text === note.text) && (noteWrapper.orderId === orderId);
  }

  public addNoteForCreation(note: Note, orderId: number, attachment?: string) {
    if (attachment) {
      note.offlineState.attachment = attachment;
    }
    this.addNoteForUpdate(note, orderId);
  }

  private addNoteForUpdate(note: Note, orderId: number) {
    this.storageService.getNotesToCreate().then(notes => {
      notes.push({ note, orderId, offlineId: uuidv4() });
      this.storageService.saveNotesToCreate(notes, () => this.stateManagerService.refreshNotes());
    })
  }

  public addServiceToStorage(service: Service, order: Order) {
    if (!order.offlineState.servicesToModify) {
      order.offlineState.servicesToModify = [];
    }
    const offlineServices = order.offlineState.servicesToModify;
    const foundServiceIndex = findOfflineServiceIndex(service, offlineServices);
    if (foundServiceIndex > -1) {
      offlineServices[foundServiceIndex] = service;
    } else {
      offlineServices.push(service);
    }
    this.addOrderToStorage(order);
    this.stateManagerService.refreshServices();
  }

  public removeServiceFromStorage(service: Service, order: Order) {
    if (!order.offlineState.servicesToModify) {
      order.offlineState.servicesToModify = [];
    }
    const offlineServices = order.offlineState.servicesToModify;
    const foundServiceIndex = findOfflineServiceIndex(service, offlineServices);
    if (foundServiceIndex > -1) {
      offlineServices.splice(foundServiceIndex, 1);
      this.addOrderToStorage(order, true);
    }
  }

  public addMonitoringToStorage(monitoring: Monitoring, order: Order) {
    order.offlineState.monitoring = monitoring;
    this.addOrderToStorage(order, false, () => this.stateManagerService.refreshLatestMonitorings());
  }

  public removeMonitoringFromStorage(order: Order, callBack: () => void) {
    order.offlineState.monitoring = undefined;
    this.addOrderToStorage(order, true, () => callBack());
  }

  public addMonitoringDataToStorage(monitoringData: MonitoringDefinition, monitoring: Monitoring, order: Order) {
    if (!monitoringData.offlineId && !monitoringData.id) {
      monitoringData.offlineId = uuidv4();
    }
    if (!monitoring.offlineState) {
      monitoring.offlineState = {};
    }
    if (!monitoring.offlineState.monitoringData) {
      monitoring.offlineState.monitoringData = [];
    }
    const offlineMonitoringData = monitoring.offlineState.monitoringData;
    const monitoringDataIndex = findMonitoringDataIndex(monitoringData, offlineMonitoringData);
    if (monitoringDataIndex > -1) {
      offlineMonitoringData[monitoringDataIndex] = monitoringData;
    } else {
      offlineMonitoringData.push(monitoringData);
    }
    order.offlineState.monitoring = monitoring;
    this.addOrderToStorage(order, false, () => this.stateManagerService.refreshLatestMonitorings());
  }

  public removeMonitoringDataFromStorage(monitoringData: MonitoringDefinition, monitoring: Monitoring, order: Order) {
    if (!monitoring.offlineState.monitoringData) {
      monitoring.offlineState.monitoringData = [];
    }
    const offlineMonitoringData = monitoring.offlineState.monitoringData;
    const monitoringDataIndex = findMonitoringDataIndex(monitoringData, offlineMonitoringData);
    if (monitoringDataIndex > -1) {
      offlineMonitoringData.splice(monitoringDataIndex, 1);
      this.addOrderToStorage(order, true);
      this.stateManagerService.refreshLatestMonitorings();
    }
  }

  public addPestToStorage(order: Order, pest: Pest) {
    if (!order.offlineState.pests) {
      order.offlineState.pests = [];
    }
    const offlinePests = order.offlineState.pests;
    const pestIndex = findPestIndex(pest, offlinePests);
    if (pestIndex > -1) {
      offlinePests[pestIndex] = pest;
    } else {
      offlinePests.push(pest);
    }
    this.addOrderToStorage(order, false);
    this.stateManagerService.refreshPests();
  }

  public removePestFromStorage(order: Order, pest: Pest) {
    if (!order.offlineState.pests) {
      order.offlineState.pests = [];
    }
    const offlinePests = order.offlineState.pests;
    const pestIndex = findPestIndex(pest, offlinePests);
    if (pestIndex > -1) {
      offlinePests.splice(pestIndex, 1);
      this.addOrderToStorage(order, true);
      this.stateManagerService.refreshPests();
    }
  }

  public addPestCatalogToStorage(ident: string) {
    this.storageService.getPestCatalogs().then(offlinePestCatalogs => {
      if (offlinePestCatalogs.every(offlineIdent => offlineIdent !== ident)) {
        offlinePestCatalogs.push(ident);
        this.storageService.savePestCatalogs(offlinePestCatalogs, () => this.stateManagerService.refreshPestCatalogs());
      }
    })
  }

  public removePestCatalogFromStorage(ident: string) {
    this.storageService.getPestCatalogs().then(offlinePestCatalogs => {
      const foundIndex = offlinePestCatalogs.indexOf(ident);
      if (foundIndex > -1) {
        offlinePestCatalogs.splice(foundIndex, 1);
        this.storageService.savePestCatalogs(offlinePestCatalogs, () => {});
      }
    })
  }

  public addAttachmentToStorage(order: Order, attachment: string) {
    if (!order.offlineState.attachments) {
      order.offlineState.attachments = [];
    }
    const offlineAttachments = order.offlineState.attachments;
    offlineAttachments.push({ attachment, uuid: uuidv4() });
    this.addOrderToStorage(order, false);
  }

  public removeAttachmentFromStorage(order: Order, attachment: OfflineAttachment) {
    if (!order.offlineState.attachments) {
      order.offlineState.attachments = [];
    }
    const offlineAttachments = order.offlineState.attachments;
    const attachmentIndex = offlineAttachments.findIndex(offlineAttachment => offlineAttachment.uuid === attachment.uuid);
    if (attachmentIndex > -1) {
      offlineAttachments.splice(attachmentIndex, 1);
      this.addOrderToStorage(order, true);
    }
  }

}

export class StoredNote {
  orderId?: number
  note?: Note
  offlineId?: string
}
