import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataService } from '../service/data.service';
import { SignaturePad } from 'angular2-signaturepad';
import { SignatureModel } from '../models/signature.model';
import { StateService } from "../service/state.service";
import { findObjectsForOrder } from "../service/state.utils";
import { Customer, Order, SignatureType } from "@wissenswerft/ibo-catalog-library";
import { OfflineCapableUpdaterService } from "../service/offline/offline-capable-updater.service";
import { recordCrash } from "../utils/crashalytics.utils";

@Component({
  selector: 'signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, OnDestroy {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public windowWidth = window.screen.width - 100;

  public userName: string;

  public signaturePadOptions = {
    'minWidth': 1,
    'canvasWidth': window.screen.width - 100,
    'canvasHeight': 400,
  };

  private readonly title: string = "Unterschrift";

  public isSignatureReady = false

  public signature: SignatureModel = new SignatureModel(null);

  public subscriptions: Subscription[] = [];

  public showLoader = false;

  private order: Order;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private appService: AppService,
    private dataService: DataService,
    private stateService: StateService,
    private offlineCapableUpdateService: OfflineCapableUpdaterService
  ) { }

  ngOnInit() {

    const user = this.activeRoute.snapshot.paramMap.get('user');
    const path = this.activeRoute.snapshot.paramMap.get('name');

    this.stateService.orderDetail$.subscribe(order => {
      this.order = order;
      if (user === "customer" && path === "orderConfirm") {
        if (order?.customerName) {
          this.userName = order.customerName;
        } else {
          findObjectsForOrder<Customer>(this.stateService, order, 'customer')
            .subscribe(customer => this.userName = customer.name);
        }
      } else if ((user === "employee" && path === "orderConfirm")) {
        if (order?.employeeName) {
          this.userName = order.employeeName;
        } else {
          this.userName = order.technician.firstname + ' ' + order.technician.lastname;
        }
      }
      if (user === "customer" && path === "controlProtocol") {
        if (this.dataService.currentMonitoring.customerSignatureName) {
          this.userName = this.dataService.currentMonitoring.customerSignatureName;
        } else {
          findObjectsForOrder<Customer>(this.stateService, order, 'customer')
            .subscribe(customer => this.userName = customer.name);
        }
      } else if ((user === "employee" && path === "controlProtocol")) {
        if (this.dataService.currentMonitoring.employeeSignatureName) {
          this.userName = this.dataService.currentMonitoring.employeeSignatureName;
        } else {
          this.userName = order.technician.firstname + ' ' + order.technician.lastname;
        }
      }

      this.dataService.callLayoutTitle(this.title);

      this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
        const path = this.activeRoute.snapshot.paramMap.get('name');
        this.router.navigate([path, order.id]).then();
      }));
    })
  }

  public valueChanged(e) {
    this.setUserSignature(e.value);
  }

  private setUserSignature = (name: string): void => {
    const user = this.activeRoute.snapshot.paramMap.get('user');
    const path = this.activeRoute.snapshot.paramMap.get('name');
    switch (path) {
      case 'orderConfirm':
        switch (user) {
          case 'customer':
            this.order.customerName = name;
            break;
          case 'employee':
            this.order.employeeName = name;
            break;
        }
        break;
      case 'controlProtocol':
        switch (user) {
          case 'customer':
            this.dataService.currentMonitoring.customerSignatureName = name;
            break;
          case 'employee':
            this.dataService.currentMonitoring.employeeSignatureName = name;
            break;
        }
        break;
    }
  }

  public drawStart(): void {
    this.isSignatureReady = true;
  }

  public deleteSignature(): void {
    this.signaturePad.clear();
    this.isSignatureReady = false;
  }

  public confirmSignature(): void {
    this.showLoader = true;
    this.setUserSignature(this.userName);
    const path = this.activeRoute.snapshot.paramMap.get('name');
    const user = this.activeRoute.snapshot.paramMap.get('user');
    const image = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
    this.offlineCapableUpdateService.uploadSignature(
      this.order,
      (path === 'orderConfirm' ? user : (user === 'customer' ? 'confirm' : 'employeeConfirm')) as SignatureType,
      image
    ).subscribe(() => {}, error => recordCrash("Error occurred while confirming signature!", error));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}

