import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IToast } from './models/toast.model';
import { ProfileInfo } from "./models/profile-info.model";


@Injectable({
  providedIn: 'root'
})
export class AppService {

  private notificationSubject: Subject<IToast> = new Subject();

  public layoutPath : string;

  public notification$ = this.notificationSubject.asObservable();

  public showMessageIcon: boolean = false;

  public showBackButton: boolean = false;

  private backButtonSubject = new Subject<void>();

  public backButtonS$ = this.backButtonSubject.asObservable();

  private settingsButtonSubject = new Subject<void>();

  public settingsButtonS$ = this.settingsButtonSubject.asObservable();

  private reloadButtonSubject = new Subject<void>();

  public reloadsButton$ = this.reloadButtonSubject.asObservable();

  public profileInformation: ProfileInfo = new ProfileInfo(null);

  constructor() { }

  public callBackButton(): void {
    this.backButtonSubject.next();
  }

  public callSettingsButton(): void {
    this.settingsButtonSubject.next();
  }

  public callReloadButton(): void {
    this.reloadButtonSubject.next();
  }

  public callProfileInfo(): ProfileInfo{
    return this.profileInformation;
  }

  public setProfileInfo(profile : ProfileInfo): void{
    this.profileInformation = profile;
  }

  public callNotification(notification : IToast): void {
    this.notificationSubject.next(notification);
  }

}
