import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order, Service, ServiceCatalog } from '@wissenswerft/ibo-catalog-library';
import { Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { OfflineCapableUpdaterService } from "../../service/offline/offline-capable-updater.service";
import { recordCrash } from "../../utils/crashalytics.utils";
import { StateService } from "../../service/state.service";

@Component({
  selector: 'performed-service',
  templateUrl: './performed-service.component.html',
  styleUrls: ['./performed-service.component.scss']
})
export class PerformedServiceComponent implements OnInit, OnDestroy {

  @Input('dataSource') performedServices: Service[] = [];

  public subscriptions: Subscription[] = [];

  public serviceCatalog: ServiceCatalog[] = [];

  private order: Order;

  public allowEditing = true;

  public servicesColumnsHeader = [{
      dataField: 'description', dataType: 'string', visible: false, allowEditing: false
    }, {
      dataField: 'designation', dataType: 'string', minWidth: 50, width: '80%', allowEditing: false
    }, {
      dataField: 'usedQuantity', dataType: 'number', minWidth: 50, width: '10%', allowEditing: true, customizeText: text => {
        if (text.valueText.includes('.')) {
          return text.valueText.replace('.', ',')
        } else {
          return text.valueText
        }
      }
    }, {
      dataField: 'unit', dataType: 'testringxt', minWidth: 50, width: '10%', allowEditing: false
    }
  ];

  constructor(
    private appService: AppService,
    private offlineCapableUpdaterService: OfflineCapableUpdaterService,
    private stateService: StateService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.stateService.orderDetail$.subscribe(order => {
      this.order = order;
      if (this.order.status === 'ERLEDIGT') {
        this.allowEditing = false;
      }
    }));
  }

  public updateService(event): void {
    const service = event.data;
    delete service.meta;
    this.subscriptions.push(this.offlineCapableUpdaterService.updateOrCreateService(service, this.order)
      .subscribe(() => {}, error => recordCrash("Error occurred while updating service!", error)));
  }

  public rowPrepared = (e) => {
    e.rowElement.style.height = '30px';
  }

  public removeService(e) {//TODO: Actually delete old service
    const service = e.data;
    service.usedQuantity = -1;
    delete service.meta;
    this.subscriptions.push(this.offlineCapableUpdaterService.updateOrCreateService(service, this.order).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
