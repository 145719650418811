
export enum Gender {
  Male = 'Male',
  Female = 'Female',
}
export class ProfileInfo {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  userlanguage: string;
  gender: Gender;
  config: {};
  id: number;
  apiAuthKey: string;
  hasAvatar: boolean;
  authorities: any[];
  accessibleScopes: AccessibleScopes[];

  constructor(data: any) {
    Object.assign(this, data);
    this.accessibleScopes = [];
    if (data?.accessibleScopes) {
      for (const accessibleScope of data.accessibleScopes) {
        this.accessibleScopes.push(new AccessibleScopes(accessibleScope));
      }
    }
  }
}

export class AccessibleScopes {
  urlKey: string;
  scopeKey: string;
  name: string;
  description: string;
  defaultLanguage: string;
  visible: boolean;
  publicApi: boolean;
  features: string[];
  isProxySendAuthHeader: boolean;
  tileIconUrl: string;
  constructor(data: any) {
    Object.assign(this, data);
  }
}
