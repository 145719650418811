import {
  ContactPerson,
  Customer, Monitoring,
  MonitoringDefinition,
  Order,
  Pest,
  Service,
  VerminMeasure
} from "@wissenswerft/ibo-catalog-library";
import { StateService } from "./state.service";
import { forkJoin, from, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { AssetDataWrapper } from "../models/asset-data-wrapper.model";
import { ObjectAndDefinitions } from "../models/objects-and-definitions.model";
import { findMonitoringDataIndex, findOfflineServiceIndex, findPestIndex } from "../utils/offline.utils";
import { Note } from "../models/note.model";
import { stringToArrayBuffer } from "../utils/file.utils";
import { StorageService } from "./offline/storage.service";
import { StoredNote } from "./offline/offline.service";

type ObjectTypes = 'customer' | 'contactPerson' | 'services' | 'verminMeasures' | 'employeeSignature' |
  'customerSignature' | 'pestsAndDefinition' | 'attachments' | 'confirmSignature' | 'employeeConfirmSignature' |
  'oldMonitoringData' | 'monitoring' | 'plannedServices';
type SupportedFetchedObject = Customer | ContactPerson | VerminMeasure[] | Service[] | AssetDataWrapper |
  ObjectAndDefinitions<Pest[]> | AssetDataWrapper[] | ObjectAndDefinitions<MonitoringDefinition[]> | ObjectAndDefinitions<Monitoring>;

export function findObjectsForOrder<T extends SupportedFetchedObject>(stateService: StateService, order: Order, objectType: ObjectTypes): Observable<T> {
  // Please don't hate me for this :(. I've had to create this method because we need peak performance while also loading everything at the start of the application.
  // We're basically loading in the foreground all the objects.
  switch (objectType) {// Single properties
    case "customer": {
      return stateService.customers.pipe(
        map(customers => (customers.find(customer => order.customer === customer.id) as T))
      );
    }
    case "contactPerson": {
      return stateService.contactPersons.pipe(
        map(contactPersons => (contactPersons.find(contactPerson => order.contactPerson === contactPerson.id) as T))
      );
    }
    case "services":
    case "plannedServices": {// Lists
      return stateService.services.pipe(
        map(services => {
          const orderServices = objectType === "services" ? order.services : order.plannedServices;
          const offlineChanges = objectType === "services" ? (order.offlineState.servicesToModify ? Object.assign([], order.offlineState.servicesToModify) : []) : [];
          const finalServices = [];
          services.filter(service => orderServices.includes(service.id))
            .forEach(service => { //We override with the offline state if there's any
              const offlineIndex = findOfflineServiceIndex(service, offlineChanges);
              if (offlineIndex > -1) {
                service = offlineChanges[offlineIndex];
                offlineChanges.splice(offlineIndex, 1);
              }
              finalServices.push(service);
            });
          finalServices.push(...offlineChanges);
          return finalServices.filter(service => service.plannedQuantity !== -1 && service.usedQuantity !== -1) as T;
        })
      )
    }
    case "verminMeasures": {
      return stateService.verminMeasures.pipe(
        map(verminMeasures => verminMeasures.filter(verminMeasure => order.verminMeasures.includes(verminMeasure.id)) as T)
      )
    }
    case "pestsAndDefinition":
      return stateService.pestsAndDefinition.pipe(
        map(pestsAndDefinition => {
          const offlinePests = order.offlineState?.pests ? Object.assign([], order.offlineState.pests) : [];
          const lastFetchedPests = pestsAndDefinition.object.filter(pest => order.pests.includes(pest.id));
          const finalPests = [];
          lastFetchedPests.forEach(pest => {
            const offlineIndex = findPestIndex(pest, offlinePests);
            if (offlineIndex > -1) {
              pest = offlinePests[offlineIndex];
              offlinePests.splice(offlineIndex, 1);
            }
            finalPests.push(pest);
          });
          finalPests.push(...offlinePests);
          return ({
            object: finalPests,
            dataDefinition: pestsAndDefinition.dataDefinition
          } as T)
        })
      )
    case "customerSignature": {// Assets
      return stateService.customerSignatures.pipe(
        switchMap(customerSignatures => {
          if (order.offlineState.customerSignature) {
            return from(stringToArrayBuffer(order.offlineState.customerSignature).then(arrayBuffer => {
              return { objectId: order.id, arrayBuffer: arrayBuffer } as T
            }));
          }
          return of(customerSignatures.find(customerSignatureWrapper => customerSignatureWrapper.objectId === order.id) as T);
        }),
        filter(signature => !!signature)
      )
    }
    case "employeeSignature": {
      return stateService.employeeSignatures.pipe(
        switchMap(employeeSignatures => {
          if (order.offlineState.employeeSignature) {
            return from(stringToArrayBuffer(order.offlineState.employeeSignature).then(arrayBuffer => {
              return { objectId: order.id, arrayBuffer: arrayBuffer } as T;
            }))
          }
          return of(employeeSignatures.find(employeeSignatureWrapper => employeeSignatureWrapper.objectId === order.id) as T);
        }),
        filter(signature => !!signature)
      )
    }
    case "confirmSignature": {
      return stateService.confirmSignatures.pipe(
        switchMap(confirmSignatures => {
          if (order.offlineState.confirmSignature) {
            return from(stringToArrayBuffer(order.offlineState.confirmSignature).then(arrayBuffer => {
              return { objectId: order.id, arrayBuffer: arrayBuffer } as T;
            }))
          }
          return of(confirmSignatures.find(confirmSignatureWrapper => confirmSignatureWrapper.objectId === order.id) as T);
        }),
        filter(signature => !!signature)
      )
    }
    case "employeeConfirmSignature": {
      return stateService.employeeConfirmSignatures.pipe(
        switchMap(employeeConfirmSignatures => {
          if (order.offlineState.employeeConfirmSignature) {
            return from(stringToArrayBuffer(order.offlineState.employeeConfirmSignature).then(arrayBuffer => {
              return { objectId: order.id, arrayBuffer: arrayBuffer } as T;
            }))
          }
          return of(employeeConfirmSignatures.find(employeeConfirmSignatureWrapper => employeeConfirmSignatureWrapper.objectId === order.id) as T);
        }),
        filter(signature => !!signature)
      )
    }
    case "attachments": {
      return stateService.attachments.pipe(
        switchMap(attachments => {
          const orderAttachments = attachments.filter(attachmentWrapper => attachmentWrapper.objectId === order.id);
          if (order.offlineState.attachments && order.offlineState.attachments.length > 0) {
            return forkJoin(
              order.offlineState.attachments.map(attachmentWrapper => attachmentWrapper.attachment)
                .map(attachment => from(stringToArrayBuffer(attachment)))
            ).pipe(map(attachmentBuffers => {
              orderAttachments.push(...attachmentBuffers.map(attachmentBuffer => {
                return {
                  objectId: order.id,
                  arrayBuffer: attachmentBuffer
                }
              }));
              return orderAttachments as T;
            }))
          }
          return of(orderAttachments as T);
        })
      );
    }
    case "oldMonitoringData": { // custom
      return stateService.monitoringDefinitions.pipe(
        map(monitoringDefinitions => {
          return ({
            object: monitoringDefinitions.object.filter(monitoringDefinition => order.previousMonitorings.find(previousMonitoring => previousMonitoring.monitoringData.includes(monitoringDefinition.id))),
            dataDefinition: monitoringDefinitions.dataDefinition
          } as T);
        })
      )
    }
    case "monitoring": {
      return stateService.lastMonitoringsAndDef.pipe(
        map(monitoringsAndDefinition => {
          const offlineMonitoring = order.offlineState.monitoring;
          if (offlineMonitoring) {
            const offlineMonitoringData: MonitoringDefinition[] = Object.assign([], (offlineMonitoring.offlineState?.monitoringData ?? []));
            const fetchedMonitoringData = offlineMonitoring.fetchedObjects?.monitoringData ?? [];
            offlineMonitoring.fetchedObjects.totalMonitoringData = [];
            fetchedMonitoringData.forEach(fetchedMonitoringDataPiece => {
              const foundIndex = findMonitoringDataIndex(fetchedMonitoringDataPiece, offlineMonitoringData);
              if (foundIndex > -1) {
                offlineMonitoring.fetchedObjects.totalMonitoringData.push(offlineMonitoringData[foundIndex]);
                offlineMonitoringData.splice(foundIndex, 1);
              } else {
                offlineMonitoring.fetchedObjects.totalMonitoringData.push(fetchedMonitoringDataPiece);
              }
            });
            offlineMonitoring.fetchedObjects.totalMonitoringData.push(...offlineMonitoringData);
            return ({
              object: offlineMonitoring,
              dataDefinition: monitoringsAndDefinition.dataDefinition
            } as T);
          } else {
            const monitoring = monitoringsAndDefinition.object.find(monitoring => monitoring.order === order.id);
            if (monitoring) {
              if (!monitoring.fetchedObjects) {
                monitoring.fetchedObjects = {};
              }
              monitoring.fetchedObjects.totalMonitoringData = monitoring.fetchedObjects.monitoringData ?? [];
            }
            return ({
              object: monitoring,
              dataDefinition: monitoringsAndDefinition.dataDefinition
            } as T);
          }
        })
      );
    }
  }
}

export function getNotesForOrder(stateService: StateService, storageService: StorageService, order: Order): Observable<Note[]> {
  return getNotesWithOfflineNotes(stateService, storageService).pipe(
    map(data => {
      const notes = data.onlineNotes;
      const offlineNotesToDelete = data.notesToDelete;
      const offlineNotesToCreate = data.notesToCreate;
      const orderNotes = notes.filter(note =>
        ((note.orderId === order.id) || order.idsForNotes.includes(note.orderId)) &&
        offlineNotesToDelete.every(offlineNoteToDelete => offlineNoteToDelete.note.id !== note.id)
      );
      orderNotes.push(
        ...offlineNotesToCreate.filter(offlineNoteToCreate => offlineNoteToCreate.orderId === order.id)
          .map(noteWrapper => noteWrapper.note)
      );
      orderNotes.forEach(note => {
        if (note.offlineState?.attachment) {
          stringToArrayBuffer(note.offlineState.attachment)
            .then(arrayBuffer => note.fetchedObjects.attachments = [ { objectId: note.id, arrayBuffer } ]);
        }
      })
      return orderNotes;
    })
  );
}

function getNotesWithOfflineNotes(stateService: StateService, storageService: StorageService): Observable<{ onlineNotes: Note[], notesToCreate: StoredNote[], notesToDelete: StoredNote[] }> {
  return stateService.notes.pipe(
    switchMap(onlineNotes => {
      return forkJoin([
        from(storageService.getNotesToDelete()),
        from(storageService.getNotesToCreate())
      ]).pipe(
        map(data => {
          return {
            onlineNotes,
            'notesToDelete': data[0],
            'notesToCreate': data[1]
          }
        })
      )
    }    )
  );
}

