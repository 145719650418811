import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConvertHelper, CoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Address, ContactPerson, Customer, Order, Pest, PestCatalog } from '@wissenswerft/ibo-catalog-library';
import { DxPopupComponent, DxTagBoxComponent } from 'devextreme-angular';
import { Observable, Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { CapacitorService } from '../service/capacitor.service';
import { DataService } from '../service/data.service';
import { OrderViewModel } from '../view-models/order.view-model';
import { base64StringToBlob } from 'blob-util';
import { ToastType } from '../models/toast.model';
import { DataDefinitionOptions } from '../view-models/customer-space.view-model';
import { findObjectsForOrder } from "../service/state.utils";
import { StateService } from "../service/state.service";
import { AssetDataWrapper } from "../models/asset-data-wrapper.model";
import { ObjectAndDefinitions } from "../models/objects-and-definitions.model";
import { StateManagerService } from "../service/state-manager.service";
import { OfflineCapableUpdaterService } from "../service/offline/offline-capable-updater.service";
import { Photo } from "@capacitor/camera";
import { recordCrash } from "../utils/crashalytics.utils";

@Component({
  selector: 'signature-state',
  templateUrl: './signature-state.component.html',
  styleUrls: ['./signature-state.component.scss']
})
export class SignatureStateComponent implements OnInit, OnDestroy {

  @ViewChild('choicePopup') choicePopup: DxPopupComponent;

  @ViewChild('pestTagbox') pestTagbox: DxTagBoxComponent;

  @ViewChild('pestOnSiteTagBox') pestOnSiteTagBox: DxTagBoxComponent;

  private readonly title: string = "Situation erfassen";

  private readonly base64Format: string = "data:image/png;base64,";

  public convertHelper = ConvertHelper;

  public order: Order;

  public pestCatalogs: PestCatalog[] = [];

  public contactPerson: ContactPerson;

  public address: Address;

  public attachedImages = [];

  public subscriptions: Subscription[] = [];

  public assignedPestFromCatalog = new Pest();

  public currentPests: Pest[] = [];

  public infectionLevel: string = 'Mittel';

  public toogleQuestionVisible: boolean = false;

  public priorities: DataDefinitionOptions[] = [
    { name: 'Niedrig', value: 'low' },
    { name: 'Mittel', value: 'medium' },
    { name: 'Hoch', value: 'high' }
  ];

  public signaturePadOptions = {
    'minWidth': 1,
    'canvasWidth': 200,
    'canvasHeight': 100,
    disabled: true
  };

  constructor(
    private router: Router,
    private appService: AppService,
    public dataService: DataService,
    private coreDataService: CoreDataService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private capacitorService: CapacitorService,
    private stateService: StateService,
    private stateManagerService: StateManagerService,
    private offlineCapableUpdaterService: OfflineCapableUpdaterService
  ) { }

  ngOnInit() {
    this.dataService.callLayoutTitle(this.title);
    this.subscriptions.push(this.stateService.orderDetail$.subscribe(order => {
      this.order = order;
      this.subscriptions.push(findObjectsForOrder<Customer>(this.stateService, this.order, 'customer').subscribe(customer => {
        this.address = customer.fetchedAddress;
      }));
      this.subscriptions.push(findObjectsForOrder<ContactPerson>(this.stateService, this.order, 'contactPerson').subscribe(contactPerson => {
        this.contactPerson = contactPerson;
      }));
      this.subscriptions.push(findObjectsForOrder<AssetDataWrapper[]>(this.stateService, this.order, 'attachments').subscribe(attachments => {
        this.attachedImages = attachments.map(attachment =>
          this.base64Format + btoa(
            new Uint8Array(attachment.arrayBuffer)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          )
        )
      }));
    }));
    this.appService.layoutPath = "signatureState";
    this.subscriptions.push(this.stateService.getPestCatalogs().subscribe(pestCatalogsAndDef => {
      this.pestCatalogs = pestCatalogsAndDef.object;
    }));

    this.loadPests();

    this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
      this.updateOrder(true);
    }));

  }

  public trackById(index, item) {
    return item.id;
  }

  public openProtocol(): void {
    if (this.order.status == 'ERLEDIGT') {
      this.router.navigate(['controlProtocol', this.order.id]).then();
    } else {
      this.updateOrder(false);
    }
  }

  public takePicture(useGallery: boolean): void {
    this.cancel();
    this.capacitorService.takePicture((photo: Photo) => {
      this.zone.run(() => {
        const image = new Image();
        image.src = this.base64Format + photo.base64String;
        image.height = 200;
        image.width = 200;
        this.attachedImages.splice(0, 0, image.src);
        this.subscriptions.push(this.offlineCapableUpdaterService.uploadAttachment(this.order, photo.base64String)
          .subscribe(() => {}, error => recordCrash("Error occurred while uploading attachment!", error)));
        this.choicePopup.instance.hide();
      });
    }, useGallery);
  }

  public toogleQuestion(): void {
    this.toogleQuestionVisible = !this.toogleQuestionVisible;
  }

  public openWindow(): void {
    this.choicePopup.instance.show();
  }

  public cancel(): void {
    this.choicePopup.instance.hide();
  }

  public customItemCreating(e): void {
    this.addPestCatalog(e);
  }

  private loadPests() {
    this.currentPests = [];
    this.subscriptions.push(findObjectsForOrder<ObjectAndDefinitions<Pest[]>>(this.stateService, this.order, 'pestsAndDefinition')
      .subscribe(pestsAndDef => this.currentPests = [...pestsAndDef.object]));
  }

  public assignPest(e) {
    this.assignedPestFromCatalog = { ident: e.itemData.ident };
    this.toogleQuestion();
  }

  public assignedLevel() {
    this.toogleQuestion();
    this.assignedPestFromCatalog.infestationLevel = this.infectionLevel;
    this.subscriptions.push(this.offlineCapableUpdaterService.addPest(this.order, this.assignedPestFromCatalog)
      .subscribe(() => {}, error => recordCrash("Error occurred while adding pest!", error)));
  }

  public removeTag(e) {
    if (e.event?.target.className === 'dx-tag-remove-button') {
      const pest = e.previousValue.find(item => e.value.indexOf(item) == -1);
      this.offlineCapableUpdaterService.removePest(this.order, pest);
    }
  }

  // The pest tagbox always enters a new value <regardless if it's empty or not> when pressing enter
  // But in our case, we actually want to create a pest catalog which is selectable from the dropdown.
  // We have to do this.pestTagbox.value.splice(-1) for this, but even that has issue when we don't actually
  // add a value to the pest catalog list, so we have to set a timeout to wait for it. Totally trash.
  public addPestCatalog(event): void {
    if (!this.pestCatalogs.find(catalog => catalog.ident === event.text)) {
      this.subscriptions.push(
        this.offlineCapableUpdaterService.createPestCatalog(event.text, () => this.pestTagbox.value.splice(-1)).
        subscribe(() => {}, error => recordCrash("Error occurred while adding pest catalog!", error))
      );
    } else {
      setTimeout(() => this.pestTagbox.value.splice(-1), 250)
    }
  }

  public updateOrder(goBack: boolean): void {
    this.subscriptions.push(this.offlineCapableUpdaterService.updateOrder(this.order, null, () => {
      this.cancel();
      this.appService.callNotification({ message: 'Auftrag aktualisiert', type: ToastType.SUCCESS });
      if (goBack == true) {
        this.router.navigate(['orderConfirm', this.order.id]).then();
      } else {
        this.router.navigate(['controlProtocol', this.order.id]).then();
      }
    }).subscribe(() => {}, error => recordCrash("Error occurred while updating order from signature state!", error)));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}

