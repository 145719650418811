import { Monitoring, Order } from "@wissenswerft/ibo-catalog-library";
import { ProfileInfo } from "../models/profile-info.model";

export class OrderViewModel {

    public order: Order;

    private _color: string;

    get id(): number { return this.order.id; }

    get orderNumber(): string { return this.order.orderNumber; }

    get customer(): number { return this.order.customer; }

    get pests(): number[] { return this.order.pests; }

    get plannedServices(): number[] { return this.order.plannedServices; }

    get performedServices(): number[] { return this.order.performedServices; }

    // get measures(): number[] { return this.order.measures; }

    get areas(): string[] { return this.order.areas; }

    get executionDate(): string { return this.order.executionDate; }

    get contactPerson(): number { return this.order.contactPerson; }

    get technician(): ProfileInfo { return this.order.technician; }

    get customerSignature(): number { return this.order.customerSignature; }

    get customerSignatureDate(): Date { return this.order.customerSignatureDate; }

    get confirmSignature(): number { return this.order.confirmSignature; }

    get verifiedOrderDate(): Date { return this.order.verifiedOrderDate; }

    get confirmOrderDate(): Date { return this.order.confirmOrderDate; }

    get closedContractDate(): Date { return this.order.closedContractDate; }

    get status(): string { return this.order.status; }

    get orderStateNote(): string { return this.order.orderStateNote; }

    get attachements(): number[] { return this.order.attachements; }

    get petsOnSite(): string[] { return this.order.petsOnSite; }

    get furtherAppointmentsNeeded(): string { return this.order.furtherAppointmentsNeeded; }

    get measuresByIBO(): string { return this.order.measuresByIBO; }

    get causeOfInfestation(): string { return this.order.causeOfInfestation; }

    get influencingFactors(): string { return this.order.influencingFactors; }

    get measuresForClient(): string { return this.order.measuresForClient; }

    get efficacyProvided(): string { return this.order.efficacyProvided; }

    get workTime(): number { return this.order.workTime; }

    get employeeSignature(): number { return this.order.employeeSignature; }

    get employeeConfirmSignature(): number { return this.order.employeeConfirmSignature; }

    get appointmentId(): number { return this.order.appointmentId; }

    get previousMonitorings(): Monitoring[] { return this.order.previousMonitorings; }

    get worklog(): string { return this.order.worklog; }

    get protocolStatus(): string { return this.order.protocolStatus; }

    get services(): number[] { return this.order.services; }

    get cpid(): number { return this.order.cpid; }

    get customerName(): string { return this.order.customerName; }

    get other(): string { return this.order.other; }

    get employeeName(): string { return this.order.employeeName; }

    get jobDescription(): string { return this.order.jobDescription; }

    get closedOrderDescription(): string { return this.order.closedOrderDescription; }

    get baitsRemoved(): boolean { return this.order.baitsRemoved; }

    get verminMeasures(): number[] { return this.order.verminMeasures; }

    get exported(): boolean { return this.order.exported; }

    get odooid(): number { return this.order.odooid; }

    get odooEmployeeId(): number { return this.order.odooEmployeeId; }

    get saleOrderId(): number { return this.order.saleOrderId; }

    get alreadyAccepted(): boolean { return this.order.alreadyAccepted; }

    get customerNumber(): string { return this.order.customerNumber; }

    get lastLogStatus(): string { return this.order.lastLogStatus; }

    get lastLogTimestamp(): string { return this.order.lastLogTimestamp; }

    get participants(): string { return this.order.participants; }

    get affectedAreas(): string { return this.order.affectedAreas; }

    get idsForNotes(): number[] { return this.order.idsForNotes; }

    get color(): string { return this._color; }

    set color(value) { this._color = value; }

    get object(): number {
      return this.order.object;
    }

    get comments(): any {
      return this.order.comments;
    }

    constructor(data: Order) {
        this.order = data;
    }

}

export enum Status {
    Neu = 'NEU',
    Erledigt = 'ERLEDIGT',
    Storniert = 'STORNIERT',
    NOTWENDIGEWEITERVERFOLGUNG = 'NOTWENDIGE WEITERVERFOLGUNG'
}

export enum StatusColor {
    White = 'white',
    Gray = '#c0c0c0',
    Green = '#92d36e'
}



