import { MonitoringDefinition, Pest, Service } from "@wissenswerft/ibo-catalog-library";

export function findOfflineServiceIndex(service: Service, offlineServices: Service[]): number {
  if (service.id) {
    return offlineServices.findIndex(serviceToModify => serviceToModify.id === service.id);
  } else {
    return offlineServices.findIndex(serviceToModify => serviceToModify.designation === service.designation);
  }
}

export function findPestIndex(pest: Pest, pests: Pest[]): number {
  return pests.findIndex(
    offlinePest => (offlinePest.ident === pest.ident) && (offlinePest.infestationLevel === pest.infestationLevel) && (offlinePest.odooVerminLevelId === pest.odooVerminLevelId)
  );
}

export function findMonitoringDataIndex(monitoringData: MonitoringDefinition, monitoringDataArray: MonitoringDefinition[]): number {
  if (monitoringData.offlineId) {
    return monitoringDataArray.findIndex(arrayDataPart => arrayDataPart.offlineId === monitoringData.offlineId);
  } else if (monitoringData.id) {
    return monitoringDataArray.findIndex(arrayDataPart => arrayDataPart.id === monitoringData.id);
  }
  return -1;
}
