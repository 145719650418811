import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Injectable()
export class CapacitorService {

  constructor() {
  }

  public takePicture(cameraCallback: (Photo) => void, useGalery: boolean): void {
    Camera.getPhoto({
      quality: 100,
      resultType: CameraResultType.Base64,
      source: useGalery ? CameraSource.Photos : CameraSource.Camera,
      saveToGallery: true,
      allowEditing: false,
      correctOrientation: true
    }).then(cameraCallback);
  }
}
