import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataService } from '../service/data.service';
import { ConvertHelper, CoreDataService } from '@wissenswerft/core/data';
import { Address, ContactPerson, Customer, Order, Pest, Service, VerminMeasure } from '@wissenswerft/ibo-catalog-library';
import { StateService } from "../service/state.service";
import { findObjectsForOrder } from "../service/state.utils";
import { StorageService } from "../service/offline/storage.service";

@Component({
  selector: 'order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit, OnDestroy {

  private readonly title: string = "Auftrag Details";

  public order: Order;

  public convertHelper = ConvertHelper;

  public customer: Customer;

  public pest: Pest;

  public contactPerson: ContactPerson;

  public address: Address;

  public plannedServices: Service[] = [];

  public verminsMeasure: VerminMeasure[] = [];

  public subscriptions: Subscription[] = [];

  public showLoader: boolean = true;

  public verminMeasureColumns = [{
      dataField: 'pest', caption: '', dataType: 'string', minWidth: 50, width: '20%'
    }, {
      dataField: 'isSetup', caption: 'Objekteinrichtung', dataType: 'boolean', minWidth: 50, width: '30%'
    }, {
      dataField: 'isCombat', caption: 'Grundbekämpfung', dataType: 'boolean', minWidth: 50, width: '30%'
    }, {
      dataField: 'isControl', caption: 'Kontrolle', dataType: 'boolean', minWidth: 50, width: '20%'
    }
  ];

  constructor(
    private appService: AppService,
    private router: Router,
    public dataService: DataService,
    private coreDataService: CoreDataService,
    private stateService: StateService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.dataService.callLayoutTitle(this.title);
    this.dataService.plannedServices = [];

    this.subscriptions.push(this.stateService.orderDetail$.subscribe(orderDetail => {
      this.stateService.currentOrder = orderDetail;
      this.order = orderDetail;
      this.appService.layoutPath = "orderDetail";
      this.setOrderData();
      this.showLoader = false;
    }));

    this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
      if (window['deviceReady'] == true) {
        document.addEventListener("backbutton", function (e) {
          this.backToOrderList();
        });
      }
      this.backToOrderList();
    }));
  }

  private setOrderData() {
    this.subscriptions.push(findObjectsForOrder<Customer>(this.stateService, this.order, 'customer').subscribe(customer => {
      this.customer = customer;
      this.address = this.customer.fetchedAddress;
    }));
    this.subscriptions.push(findObjectsForOrder<ContactPerson>(this.stateService, this.order, 'contactPerson').subscribe(contactPerson => {
      this.contactPerson = contactPerson;
    }));
    this.subscriptions.push(findObjectsForOrder<Service[]>(this.stateService, this.order, 'plannedServices').subscribe(services => {
      this.plannedServices = services;
    }));
    this.subscriptions.push(findObjectsForOrder<VerminMeasure[]>(this.stateService, this.order, 'verminMeasures').subscribe(verminMeasures => {
      this.verminsMeasure = verminMeasures;
    }));
  }

  public goToConfirmOrder(): void {
    this.appService.showBackButton = true;
    this.appService.showMessageIcon = true;
    this.router.navigate(['orderConfirm', this.order.id]).then();
  }

  private backToOrderList = (): void => {
    this.router.navigateByUrl('orderList').then();
    this.appService.showBackButton = false;
    this.appService.showMessageIcon = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
