import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CoreDataService, TypeKey } from '@wissenswerft/core/data';
import { NavigationTours, Order } from '@wissenswerft/ibo-catalog-library';
import { DxListComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataService } from '../service/data.service';
import { DataDefinitionViewModel } from '../view-models/customer-space.view-model';
import { OrderViewModel, Status, StatusColor } from '../view-models/order.view-model';
import { OrderlistItemComponent } from './order-list-item/order-list-item.component';
import { StateService } from "../service/state.service";
import { ProfileInfo } from "../models/profile-info.model";
import { InternetService } from "../service/offline/internet.service";
import { ToastType } from "../models/toast.model";
import { AppLauncher } from '@capacitor/app-launcher';

declare let startApp: any
@Component({
    selector: 'order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss']
})
export class OrderlistComponent implements OnDestroy {

    @ViewChild('orderList') dxOrderList: DxListComponent;

    @ViewChild('item') item: OrderlistItemComponent;

    public profileInformation: ProfileInfo = new ProfileInfo(null);

    private readonly title: string = "Aufträge";

    public subscriptions: Subscription[] = [];

    public ordersList: OrderViewModel[] = [];

    public allOrdersList: OrderViewModel[] = [];

    public todayOrders: OrderViewModel[] = [];

    public showAllNewOrders = true;

    public accessibleNavigation: NavigationTours;

    constructor(
      private stateService: StateService,
      private router: Router,
      private appService: AppService,
      public dataService: DataService,
      public coreDataService: CoreDataService,
      private internetService: InternetService
    ) {
      this.appService.showBackButton = false;
      this.dataService.callLayoutTitle(this.title);
      this.prepareOrders();
      this.subscriptions.push(this.appService.reloadsButton$.subscribe(() => {
        if (this.internetService.isOnline) {
          this.ordersList = [];
          this.allOrdersList = [];
          this.todayOrders = [];
          this.stateService.initializeData();
        } else {
          appService.callNotification({ message: 'Du bist offline. Eine Synchronisation mit dem Server ist jetzt nicht möglich.', type: ToastType.INFO });
        }
      }));
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public openMap(graphmasterId: string): void {
      AppLauncher.openUrl({ url: 'com.nunav.logistics://tour.load?tourId=' + graphmasterId })
        .then();
    }

    private prepareOrders(){
      const currentDate = new Date();
        this.ordersList = [];
        this.todayOrders = [];
        this.allOrdersList = [];
        this.subscriptions.push(this.stateService.profileInfo.subscribe(profileInformation => {
          this.profileInformation = profileInformation;
          this.appService.setProfileInfo(new ProfileInfo(this.profileInformation));
        }));
        this.subscriptions.push(this.stateService.orders.subscribe(orders => {
          this.ordersList = [];
          this.allOrdersList = [];
          this.todayOrders = [];
          orders.map((order: Order) => {
            const orderViewModel = new OrderViewModel(order);
            switch (order.status) {
              case Status.Storniert: {
                orderViewModel.color = StatusColor.Gray;
                break;
              }
              case Status.Erledigt:
              case Status.NOTWENDIGEWEITERVERFOLGUNG: {
                orderViewModel.color = StatusColor.Green;
                break;
              }
              default: {
                orderViewModel.color = StatusColor.White;
              }
            }
            if (new Date(order.executionDate).toISOString().substring(0, 10) === currentDate.toISOString().substring(0, 10)) {
              this.todayOrders.push(orderViewModel);
            }
            this.ordersList.push(orderViewModel);
            this.allOrdersList.push(orderViewModel);
          });
          if (this.ordersList.length > 0) {
            this.ordersList = this.sortOrderList(this.ordersList);
          } else {
            this.dataService.loadingVisible = false;
          }
        }));
        this.subscriptions.push(this.stateService.tour.subscribe(tour => this.accessibleNavigation = tour));
        this.subscriptions.push(this.stateService.serviceDataDefinition.subscribe(serviceDataDefinition => {
          this.dataService.definitionsVM[TypeKey.service] = serviceDataDefinition;
          this.dataService.definitionsVM[TypeKey.service].definitionVM = new DataDefinitionViewModel(serviceDataDefinition, {});
        }));
        this.subscriptions.push(this.stateService.orderDataDefinition.subscribe(orderDataDefinition => {
          this.dataService.definitionsVM[TypeKey.order] = orderDataDefinition;
          this.dataService.definitionsVM[TypeKey.order].definitionVM = new DataDefinitionViewModel(orderDataDefinition, {});
        }));
    }

    public filterOrderList(): void {
      if (this.showAllNewOrders === false) {
          if (this.todayOrders.length > 0) {
              this.ordersList = this.sortOrderList(this.todayOrders);
          } else {
              this.ordersList = [];
          }
      } else {
          if (this.allOrdersList.length > 0) {
              this.ordersList = this.sortOrderList(this.allOrdersList);
          } else {
              this.ordersList = [];
          }
      }
    }

    public trackById(index, item): void {
        return item.id;
    }

    private sortOrderList(ordersList: OrderViewModel[]): OrderViewModel[] {
        return ordersList.sort((a, b) => new Date(b.executionDate).valueOf() - new Date(a.executionDate).valueOf())
    }

}



