import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule, DxFormModule, DxGalleryModule,
  DxListModule, DxLoadPanelModule,
  DxNumberBoxModule, DxPopoverModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule,
  DxSelectBoxModule, DxSwitchModule, DxTagBoxModule, DxTextAreaModule, DxTextBoxModule,
  DxToastModule,
  DxToolbarModule
} from 'devextreme-angular';
import { DataService } from './service/data.service';
import { ControlProtocolComponent } from './control-protocol/control-protocol.component';
import { CapacitorService } from './service/capacitor.service';
import { MonitoringComponent } from './control-protocol/monitoring/monitoring.component';
import { ServiceAndProductComponent } from './control-protocol/add-service-product/service-product.component';
import { PerformedServiceComponent } from './control-protocol/performed-service/performed-service.component';
import { ToastComponent } from './toast/toast.component';
import { OrderDetailComponent } from "./order-detail/order-detail.component";
import { OrderlistItemComponent } from "./order-list/order-list-item/order-list-item.component";
import { OrderlistComponent } from "./order-list/order-list.component";
import { SignaturePadModule } from "angular2-signaturepad";
import { OrderConfirmComponent } from "./order-confirm/order-confirm.component";
import { NoteListComponent } from "./note-list/note-list.component";
import { NoteComponent } from "./note/note.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SignatureStateComponent } from "./signature-state/signature-state.component";
import { SignatureComponent } from "./signature/signature.component";
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./providers/auth.interceptor";
import { InternetService } from "./service/offline/internet.service";
import { OfflineService } from "./service/offline/offline.service";
import { OfflineCapableUpdaterService } from "./service/offline/offline-capable-updater.service";
import { SyncService } from "./service/offline/sync.service";
import { MonitoringService } from "./service/offline/monitoring.service";
import { ServiceProductService } from "./service/offline/service-product.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreDataService, PersistenceService } from "@wissenswerft/core/data";
import { IonicStorageModule, Storage } from "@ionic/storage-angular";
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';
import { StorageService } from "./service/offline/storage.service";

// Have to await storage initialization to make sure we have access to it
export function onAppInit(storageService: StorageService): () => Promise<void> {
  return () => storageService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    OrderDetailComponent,
    OrderlistComponent,
    OrderlistItemComponent,
    OrderConfirmComponent,
    NoteListComponent,
    NoteComponent,
    ControlProtocolComponent,
    MonitoringComponent,
    PerformedServiceComponent,
    ServiceAndProductComponent,
    SignatureStateComponent,
    SignatureComponent,
    ToastComponent,
    LoginComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    DxToolbarModule,
    DxDropDownBoxModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxToastModule,
    DxNumberBoxModule,
    DxListModule,
    DxLoadPanelModule,
    DxDataGridModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxPopoverModule,
    SignaturePadModule,
    DxGalleryModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxButtonModule,
    FlexLayoutModule,
    DxScrollViewModule,
    DxTextAreaModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxFormModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IonicStorageModule.forRoot()
  ],
  providers: [
    StorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      deps: [ StorageService, Storage ],
      multi: true
    },
    DataService,
    DatePipe,
    CapacitorService,
    {  provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    InternetService,
    OfflineService,
    OfflineCapableUpdaterService,
    SyncService,
    MonitoringService,
    ServiceProductService,
    CoreDataService,
    PersistenceService,
    HttpClient
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
