import { SignatureType } from "@wissenswerft/ibo-catalog-library";

export function signatureTypeToFieldName(signatureType: SignatureType): string {
  switch (signatureType) {
    case 'employeeConfirm':
      return 'employeeConfirmSignature';
    case 'confirm':
      return 'confirmSignature';
    case 'employee':
      return 'employeeSignature';
    case 'customer':
      return 'customerSignature';
  }
}
