import { AssetDataWrapper } from "./asset-data-wrapper.model";
import { ProfileInfo } from "./profile-info.model";

export class Note {
    id: number;
    additionalContexts?: string[];
    attachments?: any[];
    author: ProfileInfo;
    created: string;
    lastEdited?: Date;
    mainContext?: string;
    mainContextLabel?: string;
    state?: 'ACTIVE' | 'DELETED';
    text: string;
    // Used in the app for easy mapping
    orderId?: number;
    // Used in offline saving
    offlineId?: string;
    fetchedObjects: {
      attachments?: AssetDataWrapper[]
    }
    offlineState: {
      attachment?: string
    }
    constructor(data) {
        Object.assign(this, data);
        this.offlineState = {};
        this.fetchedObjects = {};
    }
}
