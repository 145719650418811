import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { PersistenceService, TypeKey } from "@wissenswerft/core/data";
import { environment } from "../../../environments/environment";
import { StateService } from "../state.service";
import { map, switchMap, take } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { ProfileInfo } from "../../models/profile-info.model";

@Injectable({
  providedIn: 'root'
})
export class ErrorSubmissionService {

  constructor(
    private persistenceService: PersistenceService,
    private stateService: StateService,
    private storageService: StorageService
  ) { }

  public submitUserIssue(message: string): Observable<any> {
    return this.stateService.profileInfo.pipe(
      take(1),
      switchMap(profile => this.sendExportData(profile, message))
    );
  }

  public submitAutomaticIssue(message: string, object?: any): void {
    this.stateService.profileInfo.pipe(
      take(1),
      switchMap(profile => this.persistenceService.addObjectForInsert(TypeKey.errorSubmission, {
        version: environment.version,
        account: profile.id,
        database: object ?? {},
        message: `Automatic error recording: ${message}`
      }))
    ).subscribe();
  }

  private sendExportData(profile: ProfileInfo, message: string) {
    return this.storageService.exportData().pipe(
      map(exportedData => {
        return {
          'orders': exportedData[0],
          'pestCatalogs': exportedData[1],
          'notesToDelete': exportedData[2],
          'notesToCreate': exportedData[3]
        }
      }),
      switchMap(exportedData => this.persistenceService.addObjectForInsert(TypeKey.errorSubmission, {
          version: environment.version,
          account: profile.id,
          database: exportedData,
          message
        })
      )
    );
  }
}
