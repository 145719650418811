import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConvertHelper, TypeKey } from '@wissenswerft/core/data';
import {
  Address,
  Area, ContactPerson,
  Customer,
  Monitoring,
  MonitoringDefinition,
  Order,
  Service
} from '@wissenswerft/ibo-catalog-library';
import { SignaturePad } from 'angular2-signaturepad';
import { DxButtonComponent, DxPopupComponent, DxTextAreaComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataService } from '../service/data.service';
import { ToastType } from '../models/toast.model';
import { DataDefinitionViewModel, DataDefinitionOptions } from '../view-models/customer-space.view-model';
import { MonitoringDefinitionViewModel } from '../view-models/monitoring-definition.view-model';
import { Status } from '../view-models/order.view-model';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { findObjectsForOrder } from "../service/state.utils";
import { AssetDataWrapper } from "../models/asset-data-wrapper.model";
import { StateService } from "../service/state.service";
import { ObjectAndDefinitions } from "../models/objects-and-definitions.model";
import { OfflineCapableUpdaterService } from "../service/offline/offline-capable-updater.service";
import { recordCrash } from "../utils/crashalytics.utils";
import { MONITORING_CODES } from '../utils/monitoring.utils';


@Component({
  selector: 'control-protocol',
  templateUrl: './control-protocol.component.html',
  styleUrls: ['./control-protocol.component.scss']
})
export class ControlProtocolComponent implements OnInit, OnDestroy {

  @ViewChild('addMonitoringPopup') addMonitoringPopup: DxPopupComponent;

  @ViewChild('closeOrderWindow') closeOrderWindow: DxPopupComponent;

  @ViewChild('customerSignaturePad') customerSignaturePad: SignaturePad;

  @ViewChild('employeeSignaturePad') employeeSignaturePad: SignaturePad;

  @ViewChild('monitoringComponent') monitoringComponent: MonitoringComponent;

  @ViewChild('area') dxAreaSelectBox: DxTextAreaComponent;

  @ViewChild('addMonitoringButton') addMonitoringButton: DxButtonComponent;

  private readonly title: string = "Bekämpfungsprotokoll";

  private readonly base64Format: string = "data:image/png;base64,";

  public convertHelper = ConvertHelper;

  public isContractSigned = false;

  public monitoringData: MonitoringDefinitionViewModel[] = [];

  public newMonitoringData: MonitoringDefinitionViewModel[] = [];

  public showMonitoringHistory = false;

  public performedServices: Service[] = [];

  public confirmProtocol = false;

  public order: Order;

  public orders: Order[] = [];

  public areas: Area[] = [];

  public address: Address;

  public contactPerson: ContactPerson;

  public confirmDate: string;

  public confirmed = false;

  public closeContractStatus: string;

  public isContractConfirmed = false;

  public followUpNeeded = true;

  public baitsRemoved = true;

  public closeContractDescription: string;

  public subscriptions: Subscription[] = [];

  public monitoringDefinition: MonitoringDefinition = new MonitoringDefinition({});

  public monitoring: Monitoring = new Monitoring({});

  public previousMonitorings: Monitoring[] = [];

  public previousMonitoringDefinition: MonitoringDefinitionViewModel[] = [];

  public assignedMonitoringList: MonitoringDefinition[] = [];

  public backToOrders = false;

  public showConfirmDate = false;

  public effectiveness: string;

  public monitoringCode = MONITORING_CODES;

  isContractClosed = false;

  isCompleted = false;

  public priorities: DataDefinitionOptions[] = [
    { name: 'Ja', value: 'yes' },
    { name: 'Nein', value: 'no' },
    { name: 'teilweise', value: 'partly' },
    { name: 'Keine Angabe', value: null }
  ];

  public needOrderFollowup: DataDefinitionOptions[] = [
    { name: 'Ja', value: 'yes' },
    { name: 'Nein', value: 'no' }
  ];

  public baitsRemovedOptions: DataDefinitionOptions[] = [
    { name: 'Ja', value: true },
    { name: 'Nein', value: false }
  ];

  public signaturePadOptions = {
    'minWidth': 1,
    'canvasWidth': 200,
    'canvasHeight': 100,
    disabled: true
  };

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private appService: AppService,
    public dataService: DataService,
    private stateService: StateService,
    private offlineCapableUpdaterService: OfflineCapableUpdaterService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.stateService.orderDetail$.subscribe(order => {
      this.order = order;
      this.subscriptions.push(findObjectsForOrder<ContactPerson>(this.stateService, this.order, 'contactPerson').subscribe(contactPerson => {
        this.contactPerson = contactPerson;
      }));
      this.subscriptions.push(findObjectsForOrder<Customer>(this.stateService, this.order, 'customer').subscribe(customer => {
        this.address = customer.fetchedAddress;
      }));
      this.loadData();
    }));
    this.subscriptions.push(this.appService.backButtonS$.subscribe(() => this.updateOrder()));
  }

  private loadData() {
    this.monitoringDefinition.secondCheck = false;
    this.appService.layoutPath = "controlProtocol";
    this.dataService.callLayoutTitle(this.title);
    this.performedServices = [];
    this.subscriptions.push(findObjectsForOrder<AssetDataWrapper>(this.stateService, this.order, 'confirmSignature').subscribe(assetWrapper => {
      const imageBase = this.base64Format + btoa(
        new Uint8Array(assetWrapper.arrayBuffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      this.customerSignaturePad.fromDataURL(imageBase);
      this.isContractSigned = true;
    }));

    this.subscriptions.push(findObjectsForOrder<AssetDataWrapper>(this.stateService, this.order, 'employeeConfirmSignature').subscribe(assetWrapper => {
      const imageBase = this.base64Format + btoa(
        new Uint8Array(assetWrapper.arrayBuffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      this.employeeSignaturePad.fromDataURL(imageBase);
      this.confirmDate = this.dataService.prepareSignatureDate(true);
      this.confirmProtocol = true;
      this.confirmed = true;
      this.showConfirmDate = true;
    }));

    if (this.order.verifiedOrderDate) {
      this.isContractSigned = true;
    }

    if (this.order.closedContractDate) {
      this.isContractClosed = true;
      this.confirmDate = this.order.confirmOrderDate?.toString();
      this.confirmed = true;
      this.showConfirmDate = true;
      this.isContractConfirmed = true;
    }

    if (this.order.status === Status.NOTWENDIGEWEITERVERFOLGUNG || this.order.status === Status.Erledigt) {
      this.isCompleted = true;
    }

    this.subscriptions.push(findObjectsForOrder<Service[]>(this.stateService, this.order, 'services')
      .subscribe(services => this.performedServices = services));

    this.subscriptions.push(findObjectsForOrder<ObjectAndDefinitions<MonitoringDefinition[]>>(this.stateService, this.order, 'oldMonitoringData')
      .subscribe(oldMonitoringDataAndDef => {
        const definitions = oldMonitoringDataAndDef.dataDefinition;
        this.dataService.definitionsVM[TypeKey.monitoringDefinition] = definitions;
        const monitoringgDefinitionVM = new DataDefinitionViewModel(definitions, {});
        this.dataService.definitionsVM[TypeKey.monitoringDefinition].definitionVM = monitoringgDefinitionVM;

        this.previousMonitorings = this.order.previousMonitorings;
        this.previousMonitorings.map(previousMonitoring => {//TODO: Optimize this
          oldMonitoringDataAndDef.object.filter(oldMonitoringData => previousMonitoring.monitoringData.includes(oldMonitoringData.id))
            .map(oldMonitoringData => new MonitoringDefinitionViewModel(oldMonitoringData))
            .forEach(oldMonitoringData => {
              oldMonitoringData.groupByDate = this.convertHelper.toDate(previousMonitoring.date?.toString());
              this.previousMonitoringDefinition.push(oldMonitoringData);
            })
        });
      }));

    this.subscriptions.push(findObjectsForOrder<ObjectAndDefinitions<Monitoring>>(this.stateService, this.order, 'monitoring')
      .subscribe(monitoringAndDef => {
        this.dataService.definitionsVM[TypeKey.monitoring] = monitoringAndDef.dataDefinition;
        const monitoringgDefinitionVM = new DataDefinitionViewModel(monitoringAndDef.dataDefinition, {});
        this.dataService.definitionsVM[TypeKey.monitoring].definitionVM = monitoringgDefinitionVM;
        if (!monitoringAndDef.object) {
          monitoringAndDef.object = new Monitoring({});
          monitoringAndDef.object.fetchedObjects = {};
          monitoringAndDef.object.offlineState = {};
          monitoringAndDef.object.date = new Date();
          monitoringAndDef.object.order = this.order.id;
        }
        this.monitoring = monitoringAndDef.object;
        this.effectiveness = this.monitoring.effectiveness;
        this.dataService.currentMonitoring = this.monitoring;
        this.prepareMonitoring();
      }));

  }

  public openFollowUp(): void {
    if (this.order.status !== Status.Erledigt && this.order.status !== Status.NOTWENDIGEWEITERVERFOLGUNG) {
      this.closeOrderWindow.instance.show();
    } else {
      this.router.navigateByUrl('orderList').then();
    }
  }

  public closeContract(): void {
    this.order.furtherAppointmentsNeeded = this.closeContractStatus;
    this.appService.showBackButton = false;
    this.appService.showMessageIcon = false;
    this.dataService.currentMonitorings = [];
    this.backToOrders = true;
    switch (this.order.furtherAppointmentsNeeded) {
      case 'yes':
        if (this.order.status !== Status.Erledigt) {
          this.order.status = Status.NOTWENDIGEWEITERVERFOLGUNG;
          this.updateMonitoring(false);
          this.updateOrder();
        } else {
          this.router.navigateByUrl('orderList').then();
        }
        break;
      case 'no':
        this.order.baitsRemoved = this.baitsRemoved;
        this.order.closedOrderDescription = this.closeContractDescription;
        if (this.order.status !== Status.Erledigt) {
          this.order.status = Status.Erledigt;
          this.updateMonitoring(false);
          this.updateOrder();
        } else {
          this.router.navigateByUrl('orderList').then();
        }
    }
  }

  public addServiceProduct(): void {
    this.appService.showBackButton = true;
    this.appService.showMessageIcon = true;
    this.router.navigateByUrl('serviceAndProduct').then();
  }

  public signContract(): void {
    this.appService.showBackButton = true;
    this.appService.showMessageIcon = true;
    this.router.navigate(['signature', 'controlProtocol', 'customer']).then();
  }

  public openTagDialog(): void {
    this.addMonitoringPopup.instance.show();
    this.monitoringDefinition = new MonitoringDefinition({});
    this.dxAreaSelectBox.instance.reset();
  }

  public closePopup(): void {
    this.clearWindow();
    this.addMonitoringPopup.instance.hide();
  }

  public clearWindow(): void {
    this.addMonitoringPopup.instance.hide();
    this.dxAreaSelectBox.instance.reset();
  }

  public updateMonitoringDef(e): void {
    this.subscriptions.push(this.offlineCapableUpdaterService.updateMonitoringData(e.data.monitoring, this.monitoring, this.order)
      .subscribe(() => {}, error => recordCrash("Error occurred while updating monitoring definition!", error)));
  }

  public updateMonitoring(goToOrderList: boolean) {
    this.monitoring.effectiveness = this.effectiveness;
    delete this.monitoring['meta'];
    this.subscriptions.push(this.offlineCapableUpdaterService.updateMonitoring(this.monitoring, this.order, goToOrderList)
      .subscribe(() => {}, error => recordCrash("Error occurred while updating monitoring!", error)));
  }

  public confirm(): void {
    this.appService.showBackButton = true;
    this.appService.showMessageIcon = true;
    this.router.navigate(['signature', 'controlProtocol', 'employee']).then();
  }

  public changeOrderWorkTime(event) {
    this.order.workTime = (event.value / 60);
  }

  public updateOrder(): void {
    this.subscriptions.push(this.offlineCapableUpdaterService.updateOrder(this.order, null, () => {
      if (this.backToOrders == true) {
        this.router.navigateByUrl('orderList').then();
        this.appService.callNotification({ message: 'Auftrag abgeschlossen', type: ToastType.SUCCESS });
      } else {
        this.monitoring.date = new Date();
        this.updateMonitoring(true);
      }
    }).subscribe(() => {}, error => recordCrash("Error occurred while updating order from control protocol!", error)));
  }

  private prepareMonitoring(): void {
    if (this.monitoring?.fetchedObjects?.totalMonitoringData?.length > 0) {
      this.newMonitoringData = this.monitoring.fetchedObjects.totalMonitoringData.map(monitoring => new MonitoringDefinitionViewModel(monitoring));
      this.monitoringData = this.newMonitoringData;
    }
  }

  public addMonitoringDefinition(): void {
    this.subscriptions.push(this.offlineCapableUpdaterService.addMonitoringData(
      this.monitoringDefinition,
      this.monitoring,
      this.order,
      () => this.addMonitoringPopup.instance.hide()
    ).subscribe(() => {}, error => recordCrash("Error occurred while adding monitoring definition!", error)));
  }

  public switchMonitoring(): void {
    if (this.showMonitoringHistory === true) {
      this.monitoringComponent.loadPreviousMonitoring();
      this.previousMonitoringDefinition = this.previousMonitoringDefinition?.reduce((a, b) => {
        if (a.every(item => item.id !== b.id)) {
          a.push(b)
        }
        return a;
      }, []);
      this.monitoringData = this.previousMonitoringDefinition;
      this.addMonitoringButton.disabled = true;
    } else {
      this.monitoringComponent.loadCurrentMonitoring();
      this.monitoringData = this.newMonitoringData;
      this.addMonitoringButton.disabled = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
